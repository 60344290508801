import { cva } from 'class-variance-authority'
import { useRef } from 'react'
import { mergeProps, PressEvent, useButton, useFocusRing } from 'react-aria'

import Icon from '../icon/icon'
import styles from './chip.module.css'

const chipClasses = cva(styles.base, {
  variants: {
    intent: {
      primary: styles.primary,
      secondary: styles.secondary,
    },
  },
})

export interface ChipProps {
  /** The chip's label text. */
  children: string
  /** The variant of the chip. */
  intent?: 'primary' | 'secondary'
  /** Whether the chip is disabled. */
  isDisabled?: boolean
  /** Handler that is called when the close button is pressed. */
  onClose: (evt: PressEvent) => void
}

/** A chip represents a unit of information, often used for filtering or as a label. */
export const Chip = ({
  intent = 'primary',
  children,
  isDisabled,
  onClose,
}: ChipProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton(
    {
      'aria-label': `Close ${children}`,
      isDisabled,
      onPress: onClose,
    },
    ref
  )
  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <div className={chipClasses({ intent })}>
      <span className={styles.label}>{children}</span>
      <button
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={styles.closeButton}
        data-focus-visible={isFocusVisible || undefined}
        disabled={isDisabled}
      >
        <Icon icon='close' size='small' />
      </button>
    </div>
  )
}

export default Chip
