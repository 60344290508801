import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { HtIntentNode } from '../cms/hubtype/intent'
import { HtContentType } from '../cms/hubtype/node-types'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import { ContentType, InputContentType, TopContentFieldsBase } from '.'
import { InputLocale, TextLocale } from './common'

export class IntentFields extends TopContentFieldsBase {
  constructor(
    public title = '',
    public intents: string[] = [],
    public confidence = 50,
    public titleLocales: TextLocale[] = [],
    public intentsLocales: InputLocale[] = []
  ) {
    super()
  }

  contentType(): InputContentType {
    return ContentType.INTENT
  }

  static getDisplayInfo() {
    return { name: 'Intent', icon: 'brain' }
  }

  getDisplayInfo() {
    return IntentFields.getDisplayInfo()
  }

  setValuesByLocale(locale: string): void {
    this.title = LocaleManager.getTextByLocale(this.titleLocales, locale)
    this.intents = LocaleManager.getInputsByLocale(this.intentsLocales, locale)
  }

  setContentByLocale(locale: LocaleCode): void {
    this.intentsLocales = LocaleManager.setInputsByLocale(
      this.intentsLocales,
      this.intents,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.titleLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.intentsLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.titleLocales, localesToRemove)
    LocaleManager.removeLocales(this.intentsLocales, localesToRemove)
  }

  static fromHubtypeCMS(component: HtIntentNode, locale: string): IntentFields {
    const newIntent = new IntentFields()
    this.setHubtypeCmsCommonData(newIntent, component)
    newIntent.confidence = component.content.confidence
    newIntent.titleLocales = component.content.title
    newIntent.intentsLocales = component.content.intents
    newIntent.setValuesByLocale(locale)
    newIntent.code = component.code || newIntent.title

    return newIntent
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtIntentNode {
    this.setContentByLocale(locale)
    const node: HtIntentNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.INTENT,
      code: '',
      content: {
        title: this.titleLocales,
        intents: this.intentsLocales,
        confidence: this.confidence,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (this.intents.length === 0) requiredFields.push('Intent')
    if (!this.edges.some(edge => edge.sourceHandle?.includes(this.id))) {
      requiredFields.push('Connection')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyInput(
      this.intentsLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString(this.intents, value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: IntentFields): IntentFields {
    const newIntents = new IntentFields()
    TopContentFieldsBase.copyContent(content, newIntents)
    return newIntents
  }
}
