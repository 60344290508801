import { Icon, Select } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { ContentType } from '../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../UI/analytics'
import {
  HtInfoPanel,
  TextBodyBold,
  TextBodySmall,
} from '../../UI/components/base'
import {
  ActionType,
  ADD_CONDITION_VALUE,
  CONDITION_VARIABLE,
  InputAction,
  VARIABLE_FORMAT,
} from '../../UI/components/node-editor-panel/constants'
import { StyledIconWithText } from '../../UI/components/node-editor-panel/editor-styles'
import { BotVariablesSelect } from '../../UI/components/node-editor-panel/editor-widgets/bot-variables-select'
import { ButtonWidget } from '../../UI/components/node-editor-panel/editor-widgets/button-widget'
import { SortableContentWidget } from '../../UI/components/node-editor-panel/editor-widgets/sortable-widget/sortable-content-widget'
import { UniqueContentField } from '../../UI/components/node-editor-panel/editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../UI/components/node-editor-panel/node-editor-panel'
import { ConditionValueEditor } from './condition-value/edit-panel'
import { ConditionValueFields } from './condition-value/model'
import {
  AVAILABLE_VARIABLE_FORMATS,
  CustomConditionFields,
  VariableFormat,
} from './model'

export interface CustomConditionEditorProps extends EditorProps {
  nodeContent: CustomConditionFields
}

export const CustomConditionEditor = memo(
  ({ nodeContent, ...props }: CustomConditionEditorProps): JSX.Element => {
    const analytics = useAnalytics()

    const onSelectValue = (action: InputAction, value?: string) => {
      if (!value) return
      props.onChange({ type: action.actionType, fieldKey: action.key, value })
      if (action === CONDITION_VARIABLE) {
        analytics.trackEvent(TrackEventName.CUSTOM_CONDITION_VARIABLE, {
          variable_name: value,
        })
      } else if (action === VARIABLE_FORMAT) {
        analytics.trackEvent(TrackEventName.CUSTOM_CONDITION_VARIABLE_FORMAT, {
          type: value,
        })
      }
    }

    const onRemoveConditionValue = (conditionValue: ConditionValueFields) => {
      analytics.trackEvent(TrackEventName.SET_CUSTOM_CONDITION_VALUES, {
        action: 'remove',
        number_of_values: nodeContent.values.length - 1,
      })
      props.onChange({
        type: ActionType.UPDATE_CONTENT,
        fieldKey: 'values',
        value: nodeContent.values.filter(v => v.id !== conditionValue.id),
        edges: nodeContent.edges.filter(
          edge => edge.sourceHandle !== `${nodeContent.id}-${conditionValue.id}`
        ),
      })
    }

    return (
      <>
        <TextBodyBold>Custom condition</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <BotVariablesSelect
          {...props}
          onUseVariable={variable =>
            onSelectValue(CONDITION_VARIABLE, variable)
          }
          field={CONDITION_VARIABLE}
          selectedKey={nodeContent.conditionVariable}
        />
        <Select
          {...props}
          label={VARIABLE_FORMAT.label}
          placeholder={VARIABLE_FORMAT.placeholder}
          description={VARIABLE_FORMAT.helperText}
          items={AVAILABLE_VARIABLE_FORMATS}
          selectedKey={nodeContent.variableFormat}
          onChange={format => onSelectValue(VARIABLE_FORMAT, format?.id)}
          renderButtonAsItem={true}
        >
          {format => (
            <StyledIconWithText>
              <Icon icon={format.icon} />
              {format.id}
            </StyledIconWithText>
          )}
        </Select>
        {nodeContent.variableFormat &&
          nodeContent.variableFormat !== VariableFormat.BOOLEAN && (
            <>
              <SortableContentWidget
                {...props}
                contentType={ContentType.CUSTOM_CONDITION}
                nodeId={nodeContent.id}
                sortableContents={nodeContent.values}
                selectedContentId={nodeContent.selectedSubContentId}
                isRemovable={nodeContent.values.length > 1}
                isSelectable={true}
                onRemove={onRemoveConditionValue}
                header={value => (
                  <TextBodySmall key={value.id}>is {value.name}</TextBodySmall>
                )}
              >
                {value => (
                  <ConditionValueEditor
                    {...props}
                    key={value.id}
                    conditionValue={value}
                    variableFormat={nodeContent.variableFormat}
                    isSelected={value.id === nodeContent.selectedSubContentId}
                  />
                )}
              </SortableContentWidget>
              <ButtonWidget {...props} field={ADD_CONDITION_VALUE} />
            </>
          )}
        <HtInfoPanel
          title='About custom condition'
          text="To use condition variables you need to define them in the conversational app's code and then add them in the “Condition variable” dropdown by using the exact same name."
        ></HtInfoPanel>
      </>
    )
  }
)
