import { useEffect, useState } from 'react'

import { Locale } from '../../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { NodeTypes } from '../../../types'

export interface LocaleError {
  locale: Locale
  count: number
}

export interface FlowErrors {
  nodeErrors: NodeTypes[]
  localeErrors: LocaleError[]
}

export const useAlertCenter = () => {
  const { state } = useFlowBuilderSelector(ctx => ctx)
  const [errors, setErrors] = useState<FlowErrors>()

  const hasErrors = (node: NodeTypes): boolean => {
    return (
      !!node.data.errors.showErrors &&
      (node.data.errors.fieldErrors.length > 0 ||
        !!node.data.errors.hasDuplicatedCode)
    )
  }

  const processLocalesWithErrors = (
    node: NodeTypes,
    errorCountLocales: Record<string, number>
  ) => {
    node.data.errors.localesWithErrors.forEach(locale => {
      errorCountLocales[locale] = (errorCountLocales[locale] ?? 0) + 1
    })
  }

  const getErrors = (): FlowErrors => {
    const nodeErrors: NodeTypes[] = []
    const errorCountLocales: Record<string, number> = {}

    state.nodes.forEach(node => {
      if (hasErrors(node)) {
        nodeErrors.push(node)
      }
      processLocalesWithErrors(node, errorCountLocales)
    })

    const localeErrors: LocaleError[] = Object.entries(errorCountLocales)
      .filter(([locale]) => locale !== state.currentLocale.code)
      .map(([localeCode, count]) => ({
        locale: state.locales.find(l => l.code === localeCode)!,
        count,
      }))

    return { nodeErrors, localeErrors }
  }

  useEffect(() => {
    setErrors(getErrors())
  }, [state.nodes, state.currentLocale.code, state.locales])

  return { errors }
}
