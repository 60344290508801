import { Button, Icon } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import styles from '../alert-center.module.css'
import { LocaleError } from '../use-alert-center'

interface LocaleAlertProps {
  error: LocaleError
}

export const LocaleAlert = ({ error }: LocaleAlertProps) => {
  const { removeFeedbackMessages, selectLocale } = useFlowBuilderSelector(
    ctx => ctx
  )

  const goToLocale = (): void => {
    removeFeedbackMessages()
    selectLocale(error.locale)
  }

  return (
    <li className={styles.alertItem}>
      <div className={styles.alertContent}>
        <div className={styles.alertHeader}>
          <Icon icon='language' prefix='fas' />
          <span>{error.locale.getLocaleString()}</span>
        </div>
        Language contains {error.count} errors
        <Button
          size='small'
          appearance='outline'
          intent='secondary'
          className={styles.goToLanguageButton}
          onPress={goToLocale}
        >
          Go to language
        </Button>
      </div>
    </li>
  )
}
