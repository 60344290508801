import { Button, Tooltip } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { InputAction } from '../constants'
import { EditorProps } from '../node-editor-panel'

interface ButtonProps extends EditorProps {
  field: InputAction
  isButtonDisabled?: boolean
  tooltip?: string
}

export const ButtonWidget = memo((props: ButtonProps) => {
  if (props.isReadOnly) return null
  return (
    <Tooltip
      content={props.tooltip}
      isDisabled={!props.isButtonDisabled}
      placement='bottom left'
    >
      <Button
        intent='secondary'
        appearance='outline'
        isDisabled={props.isReadOnly || props.isButtonDisabled}
        onPress={() => props.onChange({ type: props.field.actionType })}
      >
        {props.field.label}
      </Button>
    </Tooltip>
  )
})
