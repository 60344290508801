import { FeedbackType } from '../../domain/feedback'
import { HtFlowBuilderData } from '../../domain/models/cms/hubtype/flow-builder-data'
import { FlowBuilderUser } from '../../domain/models/organization-models'
import { VersionHistoryData } from '../../UI/components/header/version-history-dropdown/version-history-dropdown'
import { FlowBuilderApi } from './flow-builder-api'
import { HubtypeService } from './hubtype-service'

export class FlowBuilderService {
  // TODO: Refactor in next PRs to follow same logic as other services in Knowledge Base feature
  static async getUserInfo(
    authToken: string
  ): Promise<FlowBuilderUser | undefined> {
    const data = await FlowBuilderApi.get(authToken, `flow_builder/user_info`)
    if (data.error) return undefined
    const user = await HubtypeService.getUserData(authToken, data.user_id)
    if (!user) return undefined
    return new FlowBuilderUser(data.bot_id, user)
  }

  static async loadFlow(
    authToken: string,
    flowVersion: string,
    botId: string
  ): Promise<HtFlowBuilderData | undefined> {
    const data = await FlowBuilderApi.get(
      authToken,
      `bot_flows/${botId}/versions/${flowVersion}/`
    )
    return data.error ? undefined : data
  }

  static async saveFlow(
    authToken: string,
    flowData: HtFlowBuilderData,
    botId: string
  ): Promise<FeedbackType> {
    const body = JSON.stringify(flowData)
    const data = await FlowBuilderApi.put(
      authToken,
      `bot_flows/${botId}/save/`,
      body
    )
    return data.error || FeedbackType.SUCCESS
  }

  static async publishFlow(
    authToken: string,
    version: string,
    botId: string
  ): Promise<FeedbackType> {
    const body = JSON.stringify({ version })
    const data = await FlowBuilderApi.put(
      authToken,
      `bot_flows/${botId}/publish/`,
      body
    )
    return data.error || FeedbackType.SUCCESS
  }

  static async getVersionHistory(
    authToken: string,
    botId: string
  ): Promise<VersionHistoryData[] | { error: FeedbackType }> {
    const data = await FlowBuilderApi.get(
      authToken,
      `bot_flows/${botId}/versions/`
    )
    if (data.error) return data
    return data.map((v: any) => ({
      id: v.version,
      version: v.version,
      createdAt: new Date(v.created_at),
      createdBy: v.created_by,
      isPublished: v.is_published,
    }))
  }

  static async uploadFile(
    authToken: string,
    file: File,
    botId: string
  ): Promise<string | null> {
    const sanitizedFileName = file.name.replace(/[\s?&=]/g, '-')
    const endpoint = `bot_flows/${botId}/medias/?filename=${sanitizedFileName}`
    const formData = new FormData()
    formData.append('file', file, sanitizedFileName)

    const data = await FlowBuilderApi.postFile(
      authToken,
      endpoint,
      formData,
      file.type
    )

    return data.error ? null : data.url
  }

  static async transferFlows(
    authToken: string,
    botId: string,
    targetBotId: string
  ): Promise<void> {
    try {
      await FlowBuilderApi.post(
        authToken,
        `bot_flows/${botId}/transfer/`,
        JSON.stringify({
          target_bot_id: targetBotId,
        })
      )
    } catch (error) {
      return Promise.reject({
        analytics: { code: 'transfer_failed' },
        message:
          'Failed to transfer flows. Please try again later or contact support for further assistance.',
      })
    }
  }
}
