import { useRef } from 'react'
import { Overlay, Placement, useOverlayPosition, useTooltip } from 'react-aria'
import { TooltipTriggerState } from 'react-stately'

import { Arrow } from './arrow'
import styles from './tooltip.module.css'

interface TooltipContentProps {
  state: TooltipTriggerState
  triggerRef: React.RefObject<HTMLElement>
  placement: Placement
  children: string | JSX.Element
  showArrow?: boolean
  offset?: number
}

export const TooltipContent = ({ state, ...props }: TooltipContentProps) => {
  const ref = useRef(null)
  const { tooltipProps } = useTooltip({}, state)

  const { arrowProps, placement } = useOverlayPosition({
    overlayRef: ref,
    targetRef: props.triggerRef,
    placement: props.placement,
    isOpen: state.isOpen,
    offset: props.offset || (props.showArrow ? 9 : 4),
  })

  return (
    <Overlay>
      <span
        {...tooltipProps}
        ref={ref}
        className={styles.base}
        data-placement={placement}
      >
        {props.showArrow && <Arrow {...arrowProps} placement={placement} />}
        {props.children}
      </span>
    </Overlay>
  )
}
