import { ContentType } from '../../domain/models/content-fields'
import AiMagicWandIcon from '../../UI/assets/ai-magic-wand.svg'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { Node } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'
import { KnowledgeBaseFields } from './model'

export const KnowledgeBaseBuildNode = () => {
  const displayInfo = KnowledgeBaseFields.getDisplayInfo()
  return (
    <Node
      onDragStart={event => onDragStart(event, ContentType.KNOWLEDGE_BASE)}
      draggable
    >
      <BuildComponentTooltip
        title={displayInfo.name}
        tooltipText='AI generated responses from information uploaded by your organization.'
      />
      <AiMagicWandIcon />
    </Node>
  )
}
