import { Select } from '@hubtype/ui-react-web'

import { LocaleCode } from '../../../domain/models/locales/code'
import { Locale } from '../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import styles from './locale-panel.module.css'

interface LocaleItemProps {
  currentCopyFrom?: LocaleCode
  changeCopyFrom: (copyFrom?: LocaleCode) => void
}

type None = 'none'
const NO_COPY_FROM = 'none' as None

export const CopyFromDropdown = ({
  currentCopyFrom,
  changeCopyFrom,
}: LocaleItemProps): JSX.Element => {
  const stateLocales = useFlowBuilderSelector(ctx => ctx.state.locales)

  const onChange = (value?: Locale | { id: None }) => {
    if (!value) return
    if (value.id === NO_COPY_FROM) changeCopyFrom(undefined)
    else changeCopyFrom(value.code)
  }

  const getItemText = (value: Locale | { id: None }) => {
    if (value.id === NO_COPY_FROM) return 'None'
    return `${value.language} (${value.code})`
  }

  return (
    <div className={styles.copyFrom}>
      Copy from
      <Select
        label='Copy from'
        items={[{ id: NO_COPY_FROM }, ...stateLocales]}
        selectedKey={currentCopyFrom || NO_COPY_FROM}
        buttonProps={{
          size: 'small',
          intent: 'secondary',
          width: 'fit-content',
        }}
        popoverProps={{ placement: 'bottom left', width: 'fit-content' }}
        isLabelHidden={true}
        onChange={onChange}
        renderButtonAsItem={true}
      >
        {item => <>{getItemText(item)}</>}
      </Select>
    </div>
  )
}
