import { memo } from 'react'

import { IntentNodeProps } from '../../types'
import { InputHandles } from './handles/node-handles'
import { ChipList, NodeHeader } from './node-components'
import { InputNode } from './node-styles'

const IntentNode = ({ data, selected }: IntentNodeProps) => {
  return (
    <InputNode $isSelected={selected}>
      <NodeHeader data={data} />
      <InputHandles contentId={data.getContentId()} />
      <ChipList items={data.intents} selected={selected} />
    </InputNode>
  )
}

export default memo(IntentNode)
