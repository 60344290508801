import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { MAIN_FLOW, START_NODE_ID } from '../../../UI/constants'
import { TopContentFieldsBase } from './content-bases'
import { StartContentType } from './content-types'

export class StartFields extends TopContentFieldsBase {
  constructor() {
    super()
    this.id = START_NODE_ID
    this.code = 'Start'
  }

  contentType(): StartContentType {
    return StartContentType.START
  }

  static getDisplayInfo() {
    return { name: 'Start', icon: 'start' }
  }

  getDisplayInfo() {
    return StartFields.getDisplayInfo()
  }

  toHubtypeCMS(position: XYPosition): any {
    throw new Error('Start node can not be saved in hubtype cms')
  }

  hasString(value: string): boolean {
    return false
  }

  setErrors(): void {
    this.errors.fieldErrors = []
    if (!this.edges.some(edge => edge.sourceHandle?.includes(MAIN_FLOW.id))) {
      this.errors.fieldErrors = this.getMissingFieldsErrors(['Connection'])
    }
  }

  getConnections(edges: Edge[]): NodeEdges {
    return {}
  }
}
