import { FocusableElement } from '@react-types/shared'
import { DOMAttributes } from 'react'
import { PlacementAxis } from 'react-aria'

import styles from './tooltip.module.css'

interface ArrowProps extends DOMAttributes<FocusableElement> {
  placement: PlacementAxis
}

export const Arrow = ({ placement, ...props }: ArrowProps): JSX.Element => {
  return (
    <div {...props} className={styles.arrow}>
      <svg
        width='12'
        height='6'
        viewBox='0 0 12 6'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <filter
            id='tooltip-shadow'
            x='-50%'
            y='-50%'
            width='200%'
            height='200%'
          >
            <feDropShadow
              dx='0'
              dy='0'
              stdDeviation='1.5'
              floodOpacity='0.25'
            />
          </filter>
        </defs>
        <path
          d='M6 6L-5.24537e-07 1.04907e-06L12 0L6 6Z'
          fill='#current'
          filter='url(#tooltip-shadow)'
        />
      </svg>
    </div>
  )
}
