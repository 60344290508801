import styled from 'styled-components'

import { COLORS, TextBodyBold } from '../base'
import { ellipsis, TextBody, TextBodySmall } from '../base/typography'

export const EditorPanel = styled.div`
  top: 62px;
  right: 16px;
  position: absolute;
  height: calc(100% - 78px);
  box-sizing: border-box;
  border-radius: 8px;
  width: 272px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  overflow-y: auto;
  font-size: 16px;
  color: ${COLORS.N900};
  background-color: ${COLORS.WHITE};
  gap: 16px;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);

  > button:first-child {
    position: absolute;
    top: 9px;
    right: 16px;
  }
`

export const EditorTitle = styled(TextBodyBold)`
  text-transform: capitalize;
`

export const ImageContainer = styled.div<{ $isReadOnly: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 7px 16px 7px 12px;
  border: 1px solid ${COLORS.N200};
  background: ${props => props.$isReadOnly && COLORS.N50};
  color: ${COLORS.N500};
  border-radius: 8px;
  min-height: 38px;

  img {
    max-width: ${props => (props.$isReadOnly ? '100%' : 'calc(100% - 30px)')};
    max-height: 200px;
    pointer-events: none;
  }

  button {
    align-self: flex-start;
    margin-right: -10px;
  }
`
export const ImageText = styled(TextBodySmall)`
  color: ${COLORS.N500};
`

export const ImageLabel = styled.label`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  width: calc(100% - 28px);
  height: 20px;
  cursor: pointer;
`

export const SortableItem = styled.div<{
  $isSelected: boolean
  $isDragging: boolean
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${props => (props.$isSelected ? COLORS.WHITE : COLORS.N50)};
  border-top: 1px solid ${COLORS.N200};
  border-bottom: ${props => props.$isDragging && `1px solid ${COLORS.N200}`};
`

export const StyledDraggableArea = styled.div<{
  $isSelected: boolean
  $isSubSection?: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.$isSubSection && '16px'};
  ${props => !props.$isSelected && `&:hover { background-color: #e8e8e8; }`}
`

export const StyledSubContentHeader = styled.div<{ $isSelectable?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  cursor: ${props => (props.$isSelectable ? 'pointer' : 'default')};

  ${TextBody} {
    ${ellipsis};
  }

  > button:last-child {
    margin-left: auto;
  }
`

export const EditorFields = styled.div<{
  $inCarouselContent?: boolean
  $isSubSection?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px 16px;
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.$isSubSection && '32px'};
  padding: ${props => props.$inCarouselContent && '0px'};
`

export const StyledDroppable = styled.div`
  width: calc(100% + 32px);
  margin: 0px -16px;
  border-bottom: 1px solid ${COLORS.N200};
`

export const SubContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42px;
  width: 168px;
`

export const ConditionalContentTitle = styled(SubContentTitle)<{
  $disableGripDots?: boolean
}>`
  min-height: auto;
  width: ${props => props.$disableGripDots && '198px'};
`

export const NoIntentsMessage = styled(TextBodySmall)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
`

export const EmojiPickerContainer = styled.div`
  width: 100%;
`

export const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const KeywordsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const WhatsappButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const KnowledgeEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  span:nth-child(2) {
    position: absolute;
    width: 159px;
  }
`

export const StyledDropdownFooter = styled.div`
  padding: 8px 16px 0px 16px;
  > button {
    width: 100%;
    justify-content: center;
    > span {
      width: initial;
    }
  }
`

export const StyledIconWithText = styled.div<{ $hasError?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${props => (props.$hasError ? COLORS.R600 : COLORS.N900)};

  svg:first-of-type {
    color: ${props => (props.$hasError ? COLORS.R600 : COLORS.N900)};
  }
`

export const MeaningfulElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;

  > :first-child {
    padding-bottom: 8px;
    border-bottom: 1px solid ${COLORS.N100};
    display: flex;
    gap: 8px;

    svg {
      color: ${COLORS.N500};
    }
  }
`

export const FieldsCheckboxWithIconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--color-neutral-500);
  }
`

export const FieldsCheckboxWithTooltipContainer = styled.div`
  width: 100%;
`
