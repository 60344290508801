import { ContentType } from '../../domain/models/content-fields'
import AiBrainIcon from '../../UI/assets/ai-brain.svg'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { InputNode } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'
import { SmartIntentFields } from './model'

export const SmartIntentBuildNode = () => {
  const displayInfo = SmartIntentFields.getDisplayInfo()
  return (
    <InputNode
      onDragStart={event => onDragStart(event, ContentType.SMART_INTENT)}
      draggable
    >
      <BuildComponentTooltip
        title={displayInfo.name}
        tooltipText='Let AI detect the user intention by only adding a title and a brief description of it.'
      />
      <AiBrainIcon />
    </InputNode>
  )
}
