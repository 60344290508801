import { FocusableElement } from '@react-types/shared'
import { DOMAttributes } from 'react'
import { PlacementAxis } from 'react-aria'

import styles from './popover.module.css'

interface ArrowProps extends DOMAttributes<FocusableElement> {
  placement: PlacementAxis
}

export const Arrow = ({ placement, ...props }: ArrowProps): JSX.Element => {
  return (
    <div {...props} className={styles.arrow}>
      <svg
        width='14'
        height='9'
        viewBox='0 0 14 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <filter id='shadow' x='-20%' y='-20%' width='140%' height='140%'>
            <feDropShadow dx='0' dy='1' stdDeviation='2' floodOpacity='0.2' />
          </filter>
        </defs>
        <path
          d='M7.07176 9L14 0H7.14355H0.143555L7.07176 9Z'
          fill='white'
          filter='url(#shadow)'
        />
      </svg>
    </div>
  )
}
