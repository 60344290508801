import { Item, Selection } from 'react-stately'

import { SelectItem } from '../select/select'
import {
  MultiselectMenu,
  MultiselectMenuProps,
} from './widgets/multiselect-menu'

export interface MultiselectProps<T extends SelectItem>
  extends Omit<MultiselectMenuProps<T>, 'onSelectionChange'> {
  onChange?: (value: string[]) => void
}

export const Multiselect = <T extends SelectItem>(
  props: MultiselectProps<T>
): JSX.Element => {
  const onListStateChange = (keys: Selection) => {
    const selectedValues = Array.from(keys).map(key => key.toString())
    props.onChange?.(selectedValues)
  }

  return (
    <MultiselectMenu
      {...props}
      selectionMode='multiple'
      onSelectionChange={onListStateChange}
      aria-label={props.label}
    >
      {item => (
        <Item textValue={item.name || item.id}>
          {props.children?.(item) || item.name || item.id}
        </Item>
      )}
    </MultiselectMenu>
  )
}
