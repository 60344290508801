import { SelectItem, SelectProps } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { useBotConfig } from '../../custom-hooks/use-bot-config'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import { InteractionMode } from '../../types'
import { Size } from '../base'
import { HorizontalContainer } from '../base/containers'
import { AiSettingsDropdown } from './ai-settings/ai-settings-dropdown'
import { AlertCenter } from './alert-center/alert-center'
import { useAlertCenter } from './alert-center/use-alert-center'
import { ConnectionStatus } from './connection-status/connection-status'
import { ExitButton } from './exit/exit-button'
import styles from './header.module.css'
import { LocaleDropdown } from './locale-dropdown/locale-dropdown'
import { PreviewButton } from './preview/preview-button'
import { PublishButton } from './publish/publish-button'
import { SaveIndicator } from './save/save-indicator'
import { TransferFlowsButton } from './transfer-flows/transfer-flows-button'
import {
  VersionHistoryData,
  VersionHistoryDropdown,
} from './version-history-dropdown/version-history-dropdown'

export const headerDropdownProps: Partial<SelectProps<SelectItem>> = {
  buttonProps: {
    intent: 'secondary',
    width: 'max-content',
  },
  popoverProps: {
    listMaxHeight: '450px',
    width: '240px',
    placement: 'bottom right',
  },
  isLabelHidden: true,
}

export interface HeaderProps {
  loadPublishedVersion(version: VersionHistoryData): Promise<void>
}

export const Header = memo(
  ({ loadPublishedVersion }: HeaderProps): JSX.Element => {
    const { isPreviewEnabled } = useBotConfig()
    const { state } = useFlowBuilderSelector(ctx => ctx)
    const { errors } = useAlertCenter()

    return (
      <div className={styles.header} role={ROLES.BANNER}>
        <h1 className={styles.title}>
          {state.organizationContents.currentConversationalApp.name}
          {state.interactionMode === InteractionMode.View && (
            <span>(View only)</span>
          )}
        </h1>
        <HorizontalContainer $size={Size.MEDIUM} $align='center'>
          <AiSettingsDropdown />
          <LocaleDropdown />
          <VersionHistoryDropdown loadPublishedVersion={loadPublishedVersion} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <div className={styles.fixedWidthContainer}>
            <ConnectionStatus />
            <SaveIndicator hasFlowErrors={!!errors?.nodeErrors.length} />
            <AlertCenter errors={errors} />
          </div>
          {isPreviewEnabled && <PreviewButton />}
          <PublishButton hasFlowErrors={!!errors?.nodeErrors.length} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <TransferFlowsButton />
          <ExitButton />
        </HorizontalContainer>
      </div>
    )
  }
)
