import { memo } from 'react'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { usePreviousFlows } from '../../../custom-hooks/use-previous-flows'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { Flow, StartNodeProps } from '../../../types'
import { ViewportAnimator } from '../../../utils/viewport-animator'
import { StartNodeHandle } from '../handles/node-handles'
import { FlowNote } from '../note'
import { StartNodeGoToFlowsDropdown } from './start-node-go-to-flows-dropdown'
import { StartNodeHeader } from './start-node-header'
import { StyledStartNode } from './start-node-styles'

const StartNode = ({ data }: StartNodeProps) => {
  const { state, selectFlow } = useFlowBuilderSelector(ctx => ctx)
  const analytics = useAnalytics()

  const { flowWithComputedPreviousFlows, setShowPreviousFlows } =
    usePreviousFlows()

  const onFlowSelected = (flow: Flow) => {
    const flowName = flow.name
    if (!flowName) return
    analytics.trackEvent(TrackEventName.FB_PREVIOUS_FLOWS_OPEN, {
      flow_name: flowName,
    })
    selectFlow(flow.id)
    ViewportAnimator.centerStartNode(state)
  }

  if (!flowWithComputedPreviousFlows) return null

  const hasPreviousFlows =
    flowWithComputedPreviousFlows.previousFlows.length > 0

  return (
    <>
      <StartNodeHandle
        data={data}
        currentFlowId={flowWithComputedPreviousFlows.id}
        hasPreviousFlows={hasPreviousFlows}
      />
      <StyledStartNode $hasPreviousFlows={hasPreviousFlows}>
        <StartNodeHeader
          data={data}
          currentFlowId={flowWithComputedPreviousFlows.id}
        />
        <StartNodeGoToFlowsDropdown
          setShowPreviousFlows={setShowPreviousFlows}
          flowWithComputedPreviousFlows={flowWithComputedPreviousFlows}
          onFlowSelected={onFlowSelected}
        />
      </StyledStartNode>
      <FlowNote
        flowId={flowWithComputedPreviousFlows.id}
        $positionRight={hasPreviousFlows ? '345px' : '300px'}
      />
    </>
  )
}

export default memo(StartNode)
