import { cloneElement, useRef } from 'react'
import { useTooltipTrigger } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'

import { TooltipContent } from './content'

export interface TooltipProps {
  /** The content of the tooltip. */
  content?: string | JSX.Element
  /** The element that triggers the tooltip. */
  children: React.ReactElement
  /** The placement of the tooltip with respect to its anchor element. */
  placement?:
    | 'top'
    | 'top left'
    | 'top right'
    | 'bottom'
    | 'bottom left'
    | 'bottom right'
    | 'left'
    | 'left top'
    | 'left bottom'
    | 'right'
    | 'right top'
    | 'right bottom'
  /** Whether the tooltip should be disabled, independent from the trigger. */
  isDisabled?: boolean
  /** The delay time for the tooltip to show up. */
  delay?: number
  /** The delay time for the tooltip to close. */
  closeDelay?: number
  /** Whether the tooltip should have an arrow. */
  showArrow?: boolean
  /** The offset of the tooltip. */
  offset?: number
}

/** A tooltip displays a description of an element on hover or focus. */
export const Tooltip = ({
  children,
  content,
  placement = 'bottom right',
  delay = 200,
  closeDelay = 200,
  showArrow = true,
  offset,
  ...props
}: TooltipProps): JSX.Element => {
  const state = useTooltipTriggerState({ ...props, delay, closeDelay })
  const triggerRef = useRef(null)

  const { triggerProps, tooltipProps } = useTooltipTrigger(
    props,
    state,
    triggerRef
  )

  if (!content) {
    return children
  }

  return (
    <span style={{ position: 'relative' }}>
      {cloneElement(children, { ...triggerProps, ref: triggerRef })}
      {state.isOpen && (
        <TooltipContent
          {...tooltipProps}
          state={state}
          triggerRef={triggerRef}
          placement={placement}
          showArrow={showArrow}
          offset={offset}
        >
          {content}
        </TooltipContent>
      )}
    </span>
  )
}

export default Tooltip
