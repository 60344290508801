import { cva } from 'class-variance-authority'

import styles from './badge.module.css'

const badgeClasses = cva(styles.base, {
  variants: {
    intent: {
      primary: styles.primary,
      secondary: styles.secondary,
      neutral: styles.neutral,
    },
  },
})

export interface BadgeProps {
  /** The intent of the button. */
  intent?: 'primary' | 'secondary' | 'neutral'
  /** The button's children. */
  children: string
}

/** Badges are used in different context, next to a title. They don't have interaction. */
export function Badge({ intent = 'primary', children }: BadgeProps) {
  return <div className={badgeClasses({ intent })}>{children}</div>
}

export default Badge
