import { Icon } from '@hubtype/ui-react-web'

import { ContentType } from '../../domain/models/content-fields'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { ConditionalNode } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'
import { CustomConditionFields } from './model'

export const CustomConditionBuildNode = () => {
  const displayInfo = CustomConditionFields.getDisplayInfo()
  return (
    <ConditionalNode
      onDragStart={event => onDragStart(event, ContentType.CUSTOM_CONDITION)}
      draggable
    >
      <BuildComponentTooltip
        title={displayInfo.name}
        tooltipText="Use your own personalized conditions, previously defined in the bot's code."
      />
      <Icon icon={displayInfo.icon} size='large' />
    </ConditionalNode>
  )
}
