import { memo } from 'react'

import { HandoffNodeProps } from '../../types'
import { TextBodySmall, TextSmallBold } from '../base'
import { Handles } from './handles/node-handles'
import { NodeHeader } from './node-components'
import { MessageNode, StyledList } from './node-styles'

const HandoffNode = ({ data, selected }: HandoffNodeProps) => {
  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={!data.payload} contentId={data.getContentId()} />
      <NodeHeader data={data} />
      <StyledList $hasMarginTop>
        <div>
          <TextSmallBold>Project</TextSmallBold>
          {data.queue?.project && (
            <TextBodySmall $wordBreak='break-word'>
              {data.queue?.project}
            </TextBodySmall>
          )}
        </div>
        <div>
          <TextSmallBold>Queue</TextSmallBold>
          {!data.queue?.isInvalid && (
            <TextBodySmall $wordBreak='break-word'>
              {data.queue?.name}
            </TextBodySmall>
          )}
        </div>
        {data.payload && (
          <div>
            <TextSmallBold>Bot action after agent assistance</TextSmallBold>
            <TextBodySmall>{data.payload.name}</TextBodySmall>
          </div>
        )}
      </StyledList>
    </MessageNode>
  )
}

export default memo(HandoffNode)
