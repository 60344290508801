import { cva } from 'class-variance-authority'
import React from 'react'

import styles from './tag.module.css'

const tagClasses = cva(styles.base, {
  variants: {
    intent: {
      neutral: styles.neutral,
      positive: styles.positive,
      info: styles.info,
      warning: styles.warning,
      alert: styles.alert,
      negative: styles.negative,
    },
  },
  defaultVariants: {
    intent: 'neutral',
  },
})

export interface TagProps {
  /** The intent of the tag. */
  intent?: 'neutral' | 'positive' | 'info' | 'warning' | 'alert' | 'negative'
  /** The tag's content. */
  children: React.ReactNode
  /** Optional className to apply to the tag. */
  className?: string
}

/** Tags are components that are often used to label different items, create categorization, show statuses. */
export const Tag = ({ intent = 'neutral', children, className }: TagProps) => {
  return (
    <span className={tagClasses({ intent, className })} role='status'>
      {children}
    </span>
  )
}

export default Tag
