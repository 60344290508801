import { Icon } from '@hubtype/ui-react-web'

import { ContentType } from '../../domain/models/content-fields'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { Node } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'
import { WhatsappCTAUrlButtonFields } from './model'

export const WhatsappCTAUrlButtonBuildNode = () => {
  const displayInfo = WhatsappCTAUrlButtonFields.getDisplayInfo()
  return (
    <Node
      onDragStart={event =>
        onDragStart(event, ContentType.WHATSAPP_CTA_URL_BUTTON)
      }
      draggable
    >
      <BuildComponentTooltip
        title={displayInfo.name}
        tooltipText='Add a text message along with a button that directs to a website or webview'
      />
      <div>
        <Icon icon='whatsapp' size='large' prefix='fab' />
        <Icon icon={displayInfo.icon} size='medium' />
      </div>
    </Node>
  )
}
