import { Item, Section, Selection } from 'react-stately'

import { SelectItem } from '../select/select'
import { SelectSection } from '../select/select-with-sections'
import { MultiselectProps } from './multiselect'
import { MultiselectMenu } from './widgets/multiselect-menu'

export interface MultiselectWithSectionsProps<T extends SelectItem>
  extends Omit<MultiselectProps<T>, 'items'> {
  sections: SelectSection<T>[]
}

export const MultiselectWithSections = <T extends SelectItem>({
  sections,
  ...props
}: MultiselectWithSectionsProps<T>): JSX.Element => {
  const onListStateChange = (keys: Selection) => {
    const selectedValues = Array.from(keys).map(key => key.toString())
    props.onChange?.(selectedValues)
  }

  return (
    <MultiselectMenu
      {...props}
      items={sections}
      selectionMode='multiple'
      onSelectionChange={onListStateChange}
      aria-label={props.label}
    >
      {item => (
        <Section key={item.title} items={item.items} title={item.title}>
          {item => (
            <Item textValue={item.name || item.id}>
              {props.children?.(item) || item.name || item.id}
            </Item>
          )}
        </Section>
      )}
    </MultiselectMenu>
  )
}
