import { useEffect } from 'react'

import { setFBAppStoragePreviewState } from '../../../../test-webchat-app/utils'
import { useIsFirstRender } from '../../../custom-hooks'
import { postCloseableStatusMessage } from '../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { FadeInOutContainer } from '../../base'
import styles from '../header.module.css'
import { useAutoSave } from './use-autosave'
import { useSave } from './use-save'
import { useSaveBeforeUnload } from './use-save-before-upload'
import { useSaveChangesOnKeyPress } from './use-save-changes-on-key-press'

interface ErrorButtonProps {
  hasFlowErrors: boolean
}

export const SaveIndicator = ({
  hasFlowErrors,
}: ErrorButtonProps): JSX.Element => {
  const { saveFlow } = useSave()
  const { state } = useFlowBuilderSelector(ctx => ctx)
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    postCloseableStatusMessage(state.isFlowSaved)
    //TODO: move this inside preview component
    if (!isFirstRender && state.isFlowSaved) {
      const botId = state.organizationContents.currentConversationalApp.id
      setFBAppStoragePreviewState(botId, {
        hasFlowErrors: hasFlowErrors,
        current: state.currentLocale,
        locales: state.locales,
      })
    }
  }, [state.isFlowSaved])

  useSaveChangesOnKeyPress(state.isFlowSaved, saveFlow)
  useAutoSave(state, saveFlow)
  useSaveBeforeUnload(state.isFlowSaved, saveFlow)

  return (
    <FadeInOutContainer $isActive={state.isFlowSaved && !state.isReadOnly}>
      <div className={styles.savedText}>Saved</div>
    </FadeInOutContainer>
  )
}
