import { Node } from '@react-types/shared'
import { useListBoxSection } from 'react-aria'

import { SelectItem } from '../../select/select'
import styles from '../multiselect.module.css'
import { ListItem } from './list-item'

interface ListSectionProps<T extends object> {
  key: React.Key
  section: Node<T>
}

export const ListSection = <T extends SelectItem>({
  section,
}: ListSectionProps<T>): JSX.Element => {
  const { itemProps, headingProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  return (
    <div {...itemProps} className={styles.section}>
      {section.rendered && (
        <div {...headingProps} className={styles.title}>
          {section.rendered}
        </div>
      )}
      {[...section.childNodes].map(node => (
        <ListItem key={node.key} item={node} />
      ))}
    </div>
  )
}
