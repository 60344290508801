import { memo } from 'react'

import { WhatsappButtonListSectionFields } from '../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { WhatsappButtonListNodeProps } from '../../types'
import { TextBodySmall, TextBodySmallBold } from '../base'
import { HandleType } from './handles/handle'
import { Handles } from './handles/node-handles'
import { Markdown, MarkdownText } from './markdown/markdown'
import { Condition, NodeHeader } from './node-components'
import {
  Border,
  ButtonContainer,
  MessageNode,
  StyledList,
  StyledRow,
} from './node-styles'

const WhatsappButtonListNode = ({
  data,
  selected,
}: WhatsappButtonListNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)

  const selectRow = (id: string) => {
    data.selectedSubContentId = id
    updateNode(data)
  }

  const getSectionTitle = (
    section: WhatsappButtonListSectionFields,
    index: number
  ) => {
    let sectionTitle = section.title
    if (!sectionTitle && data.sections.length > 1) {
      sectionTitle = `Section ${index + 1} title`
    }
    if (!sectionTitle) return undefined
    return <TextBodySmall>{sectionTitle}</TextBodySmall>
  }

  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader data={data} isWhatsapp={true} />
      <hr />
      <MarkdownText isSelected={selected}>
        {data.text || 'Message text to introduce button list'}
      </MarkdownText>
      <ButtonContainer $isEmpty={!data.listButtonText}>
        <Markdown>{data.listButtonText || 'Button / List header'}</Markdown>
      </ButtonContainer>
      <StyledList>
        {data.sections.map((section, sIndex) =>
          section.rows.map((row, rIndex) => (
            <Condition
              key={row.id}
              id={row.id}
              handleType={
                rIndex === 0 ? HandleType.Section : HandleType.SmallCondition
              }
              border={sIndex === 0 && rIndex === 0 ? Border.NONE : Border.TOP}
              onClick={() => selectRow(row.id)}
            >
              <StyledRow>
                {rIndex === 0 && getSectionTitle(section, sIndex)}
                <TextBodySmallBold>
                  {row.title || 'Row title'}
                </TextBodySmallBold>
              </StyledRow>
            </Condition>
          ))
        )}
      </StyledList>
    </MessageNode>
  )
}

export default memo(WhatsappButtonListNode)
