import { useEffect, useState } from 'react'

import { NodeContentType } from '../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../reducer/hooks'

interface ContentTypeItem {
  id: string
  name: string
  count?: number
}

interface ContentTypeSection {
  title: string
  items: ContentTypeItem[]
}

const CONTENT_SECTIONS = {
  Messages: [
    [NodeContentType.TEXT, 'Text'],
    [NodeContentType.CAROUSEL, 'Carousel'],
    [NodeContentType.IMAGE, 'Image'],
    [NodeContentType.KNOWLEDGE_BASE, 'Knowledge base'],
    [NodeContentType.VIDEO, 'Video'],
    [NodeContentType.WHATSAPP_BUTTON_LIST, 'ButtonList'],
    [NodeContentType.WHATSAPP_CTA_URL_BUTTON, 'URL Button'],
  ],
  Actions: [
    [NodeContentType.HANDOFF, 'Agent handoff'],
    [NodeContentType.GO_TO_FLOW, 'Go to flow'],
    [NodeContentType.BOT_ACTION, 'Bot action'],
  ],
  Conditions: [
    [NodeContentType.CHANNEL, 'Channel'],
    [NodeContentType.QUEUE_STATUS, 'Queue status'],
    [NodeContentType.COUNTRY_CONDITION, 'Country'],
    [NodeContentType.CUSTOM_CONDITION, 'Custom'],
  ],
  Inputs: [
    [NodeContentType.SMART_INTENT, 'Smart intents'],
    [NodeContentType.INTENT, 'Intents'],
    [NodeContentType.KEYWORD, 'Keywords'],
  ],
}

export function useContentTypesCount(
  isOpen: boolean,
  isMeaningfulFilter: boolean
): {
  data: ContentTypeSection[]
} {
  const { nodes, isKnowledgeBaseActive } = useFlowBuilderSelector(
    ctx => ctx.state
  )
  const [data, setData] = useState<ContentTypeSection[]>([])

  useEffect(() => {
    if (!isOpen) return

    const counts = nodes.reduce(
      (acc, node) => {
        if (isMeaningfulFilter && !node.data.isMeaningful) {
          return acc
        }

        acc[node.type] = (acc[node.type] || 0) + 1
        return acc
      },
      {} as Record<string, number>
    )

    if (!isKnowledgeBaseActive) {
      CONTENT_SECTIONS.Messages = CONTENT_SECTIONS.Messages.filter(
        item => item[0] !== NodeContentType.KNOWLEDGE_BASE
      )
    }

    const newData = Object.entries(CONTENT_SECTIONS).map(([title, items]) => ({
      title,
      items: items.map(([id, name]) => ({
        id,
        name,
        count: counts[id] || 0,
      })),
    }))

    setData(newData)
  }, [nodes, isOpen, isMeaningfulFilter])

  return { data }
}
