import { cva } from 'class-variance-authority'
import { forwardRef, useCallback, useEffect } from 'react'
import { useObjectRef, useTextField } from 'react-aria'

import Field from '../field/field'
import { TextInputProps } from '../text-input/text-input'
import styles from './text-area.module.css'

const inputClasses = cva(styles.input, {
  variants: {
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    resizable: {
      true: styles.resizable,
    },
  },
})

export interface TextAreaProps extends TextInputProps {
  /** The number of rows to display in a multiline input. */
  defaultRows?: number
  /** Whether the textarea includes a resize handle. */
  hasResizeHandle?: boolean
}

/** A field that allows users to write multiple lines of text. Text area includes a label and a field that users can type into. */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { size = 'medium', defaultRows = 4, hasResizeHandle, ...props },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { inputProps, ...fieldProps } = useTextField(
      { ...props, inputElementType: 'textarea', maxLength: undefined },
      ref
    )

    useEffect(() => {
      if (!hasResizeHandle) resizeTextArea()
    }, [inputProps.value, hasResizeHandle])

    const resizeTextArea = useCallback(() => {
      if (!ref.current) return
      ref.current.style.height = 'inherit'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }, [])

    return (
      <Field {...props} {...fieldProps} fieldRef={ref} size={size}>
        <textarea
          rows={defaultRows || 1}
          {...inputProps}
          ref={ref}
          placeholder={props.placeholder || props.label}
          aria-label={props.label}
          className={inputClasses({ size, resizable: hasResizeHandle })}
        />
      </Field>
    )
  }
)

export default TextArea
