import { Edge } from 'reactflow'

import {
  MAX_ROW_DESCRIPTION_LENGTH,
  MAX_ROW_TITLE_LENGTH,
} from '../../../../UI/constants'
import { HtWhatsappButtonListRow } from '../../cms/hubtype/whatsapp'
import { LocaleCode } from '../../locales/code'
import { LocaleManager } from '../../utils/locale-manager'
import { TextLocale } from '../common'
import { TopContentFieldsBase } from '../content-bases'
import { ContentType, SubContentType } from '../content-types'

export class WhatsappButtonListRowFields extends TopContentFieldsBase {
  constructor(
    public title = '',
    public description = '',
    public titleLocales: TextLocale[] = [],
    public descriptionLocales: TextLocale[] = []
  ) {
    super()
  }

  contentType(): SubContentType {
    return ContentType.ROW
  }

  setValuesByLocale(locale: string): void {
    this.title = LocaleManager.getTextByLocale(this.titleLocales, locale)
    this.description = LocaleManager.getTextByLocale(
      this.descriptionLocales,
      locale
    )
  }

  setContentByLocale(locale: LocaleCode): void {
    this.titleLocales = LocaleManager.setTextByLocale(
      this.titleLocales,
      this.title,
      locale
    )
    this.descriptionLocales = LocaleManager.setTextByLocale(
      this.descriptionLocales,
      this.description,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.titleLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.descriptionLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.titleLocales, localesToRemove)
    LocaleManager.removeLocales(this.descriptionLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    row: HtWhatsappButtonListRow,
    locale: LocaleCode
  ): WhatsappButtonListRowFields {
    const newWhatsappRow = new WhatsappButtonListRowFields()
    newWhatsappRow.id = row.id
    newWhatsappRow.titleLocales = row.text
    newWhatsappRow.descriptionLocales = row.description
    newWhatsappRow.setValuesByLocale(locale)
    return newWhatsappRow
  }

  toHubtypeCMS(locale: LocaleCode): HtWhatsappButtonListRow {
    this.setContentByLocale(locale)
    return {
      id: this.id,
      text: this.titleLocales,
      description: this.descriptionLocales,
    }
  }

  getErrors(edges: Edge[]): string[] {
    const missingFieldsErrors = []
    if (!this.title) missingFieldsErrors.push('Row title')
    if (!edges.some(edge => edge.sourceHandle === this.id)) {
      missingFieldsErrors.push('Connection')
    }
    const fieldErrors = this.getMissingFieldsErrors(missingFieldsErrors)
    const longFieldsErrors = []
    if (this.title.length > MAX_ROW_TITLE_LENGTH)
      longFieldsErrors.push('Row title')
    if (this.description.length > MAX_ROW_DESCRIPTION_LENGTH) {
      longFieldsErrors.push('Row description')
    }
    const fieldToLongErrors = this.getTooLongFieldsErrors(longFieldsErrors)
    return [...fieldErrors, ...fieldToLongErrors]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyText(
      this.titleLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.title, this.description], value)
  }

  getConnections(edges: Edge[]): Edge | undefined {
    return edges.find(e => e.sourceHandle === this.id)
  }

  static getCopy(
    content: WhatsappButtonListRowFields
  ): WhatsappButtonListRowFields {
    const newWhatsappButtonListRow = new WhatsappButtonListRowFields()
    TopContentFieldsBase.copyContent(content, newWhatsappButtonListRow)
    return newWhatsappButtonListRow
  }
}
