import cloneDeep from 'lodash.clonedeep'
import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import {
  flowIdToWebviewId,
  isWebview,
  webviewIdToFlowId,
} from '../../../webviews/utils'
import { HtWebviewImageNode } from '../../../webviews/webview-image'
import { HtMediaFileLocale } from '../cms/hubtype/common'
import { HtImageNode } from '../cms/hubtype/image'
import { HtContentType } from '../cms/hubtype/node-types'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import {
  AssetFields,
  ContentType,
  MessageContentType,
  TopContentFieldsBase,
} from '.'
import { AssetLocale } from './common'

export class ImageFields extends TopContentFieldsBase {
  public image?: AssetFields
  public imageLocales: AssetLocale[] = []

  contentType(): MessageContentType {
    return ContentType.IMAGE
  }

  static getDisplayInfo() {
    return { name: 'Image', icon: 'image' }
  }

  getDisplayInfo() {
    return ImageFields.getDisplayInfo()
  }

  setValuesByLocale(locale: string): void {
    this.image = LocaleManager.getImageByLocale(this.imageLocales, locale)
  }

  setContentByLocale(locale: LocaleCode): void {
    this.imageLocales = LocaleManager.setImageByLocale(
      this.imageLocales,
      this.image,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.imageLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.imageLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    component: HtImageNode | HtWebviewImageNode,
    locale: string
  ): ImageFields {
    const newImage = new ImageFields()
    this.setHubtypeCmsCommonData(newImage, component)
    newImage.imageLocales = component.content.image.map(
      htMediaFileLocale => htMediaFileLocale as AssetLocale
    )
    newImage.setValuesByLocale(locale)
    if (
      component.type === HtContentType.WEBVIEW_IMAGE &&
      component.webview_id
    ) {
      newImage.flowId = flowIdToWebviewId(component.webview_id)
    }
    return newImage
  }

  toHubtypeCMS(
    position: XYPosition,
    locale: LocaleCode
  ): HtImageNode | HtWebviewImageNode {
    this.setContentByLocale(locale)
    if (isWebview(this.flowId)) {
      const node: HtWebviewImageNode = {
        id: this.id,
        code: this.code,
        is_code_ai_generated: this.isCodeAiGenerated,
        webview_id: webviewIdToFlowId(this.flowId),
        meta: position,
        type: HtContentType.WEBVIEW_IMAGE,
        content: {
          image: this.imageLocales.map(
            assetLocale => assetLocale as HtMediaFileLocale
          ),
        },
      }
      return node
    }
    const node: HtImageNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.IMAGE,
      content: {
        image: this.imageLocales.map(
          assetLocale => assetLocale as HtMediaFileLocale
        ),
      },
    }
    return node
  }

  static cloneWithAsset(image: ImageFields, asset?: AssetFields): ImageFields {
    const clone: ImageFields = cloneDeep(image)
    clone.image = asset
    return clone
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.image) requiredFields.push('Image')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyAsset(
      this.imageLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: ImageFields): ImageFields {
    const newImage = new ImageFields()
    TopContentFieldsBase.copyContent(content, newImage)
    if (content.image) {
      newImage.image = AssetFields.getCopy(content.image)
    }
    return newImage
  }
}
