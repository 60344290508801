import { Edge, XYPosition } from 'reactflow'

import { HtFunctionNode } from '../../domain/models/cms/hubtype/function'
import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  CONDITIONAL_FUNCTIONS,
  ConditionalContentType,
  ContentType,
  TopContentFieldsBase,
} from '../../domain/models/content-fields'
import { NodeEdges } from '../../UI/components/edges/edge-utils'
import { IdMapping } from '../../UI/types'
import { ConditionValueFields } from './condition-value/model'

export interface ConditionValue {
  id: string
  name: string
}

export enum VariableFormat {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

export const AVAILABLE_VARIABLE_FORMATS = [
  { id: VariableFormat.STRING, icon: 'font' },
  { id: VariableFormat.BOOLEAN, icon: 'toggle-on' },
  { id: VariableFormat.NUMBER, icon: '2' },
]

export class CustomConditionFields extends TopContentFieldsBase {
  public variableFormat?: VariableFormat
  public selectedSubContentId?: string

  constructor(
    public values: ConditionValueFields[] = [],
    public conditionVariable = ''
  ) {
    super()
  }

  contentType(): ConditionalContentType {
    return ContentType.CUSTOM_CONDITION
  }

  static getDisplayInfo() {
    return { name: 'Custom', icon: 'split' }
  }

  getDisplayInfo() {
    return { name: 'Custom condition', icon: 'split' }
  }

  static fromHubtypeCMS(component: HtFunctionNode): CustomConditionFields {
    const newCustomConditional = new CustomConditionFields()
    this.setHubtypeCmsCommonData(newCustomConditional, component)
    component.content.result_mapping.forEach(result => {
      if (result.result !== 'default') {
        newCustomConditional.values.push(
          new ConditionValueFields(result.result.toString())
        )
      }
    })
    if ('value' in component.content.arguments[0]) {
      newCustomConditional.conditionVariable =
        component.content.arguments[0].value.toString()
      //@ts-expect-error Type 'HtArgumentType' is not assignable to type 'VariableFormat | undefined'
      newCustomConditional.variableFormat = component.content.arguments[0].type
    }
    return newCustomConditional
  }

  toHubtypeCMS(position: XYPosition): HtFunctionNode {
    const node: HtFunctionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.FUNCTION,
      content: {
        action: CONDITIONAL_FUNCTIONS.CHECK_BOT_VARIABLE,
        arguments: [
          {
            //@ts-expect-error Type 'VariableFormat' is not assignable to type 'HtArgumentType'
            type: this.variableFormat || VariableFormat.STRING,
            name: 'keyPath',
            value: this.conditionVariable,
          },
        ],
        result_mapping: [],
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.conditionVariable) requiredFields.push('Condition variable')
    if (!this.variableFormat) requiredFields.push('Variable format')
    if (this.values.some(value => value.name === '')) {
      requiredFields.push('Value')
    }
    if (
      this.variableFormat !== VariableFormat.BOOLEAN &&
      !this.edges.some(edge => edge.sourceHandle?.includes('default'))
    ) {
      requiredFields.push('Connection')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const valuesErrors = this.values.flatMap(value =>
      value.getErrors(this.variableFormat)
    )
    this.errors.fieldErrors = [...fieldErrors, ...valuesErrors]
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.code, this.conditionVariable], value)) {
      return true
    }
    const includesString = this.values.map(v => v.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): NodeEdges {
    return this.getConditionalConnections(this.values, edges)
  }

  static getCopy(content: CustomConditionFields): CustomConditionFields {
    const newCustomCondition = new CustomConditionFields()
    TopContentFieldsBase.copyContent(content, newCustomCondition)
    newCustomCondition.values = content.values.map(value =>
      ConditionValueFields.getCopy(value)
    )
    return newCustomCondition
  }

  getIdMappingForOldFields(
    oldCustomCondition: CustomConditionFields
  ): IdMapping {
    return this.getConditionalIdMappings(
      this.values,
      oldCustomCondition.values,
      oldCustomCondition.id
    )
  }
}
