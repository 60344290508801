import { cx } from 'class-variance-authority'
import { useRef } from 'react'
import { useCheckbox, useGridListSelectionCheckbox } from 'react-aria'
import { useToggleState } from 'react-stately'

import styles from '../../checkbox/checkbox.module.css'
import { useMultiselectListContext } from '../context'
import { ListItemProps } from './list-item'

export const ListCheckbox = ({ item }: ListItemProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const state = useMultiselectListContext()
  const { checkboxProps } = useGridListSelectionCheckbox(
    { key: item.key },
    state
  )
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    ref
  )

  return (
    <div className={cx(styles.checkbox, styles.medium)}>
      <input {...inputProps} className={styles.input} ref={ref} />
      <span className={styles.checkmark} />
      <div className={styles.label}>
        <span>{item.rendered}</span>
      </div>
    </div>
  )
}
