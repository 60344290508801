import { normalizeValue } from '@hubtype/util-shared'
import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useGridList } from 'react-aria'
import { ListProps, OverlayTriggerState, useListState } from 'react-stately'

import { Button } from '../../button/button'
import { SelectItem, SelectProps } from '../../select/select'
import { MultiselectStateContext } from '../context'
import styles from '../multiselect.module.css'
import { ListItem } from './list-item'
import { ListSection } from './list-section'

interface CheckboxListProps<T extends SelectItem>
  extends Omit<ListProps<T>, 'children' | 'disabledKeys' | 'items'>,
    SelectProps<T> {
  /** The state of the overlay trigger. */
  overlayState: OverlayTriggerState
  /** The text of the apply button. */
  applyButtonText?: string
  /** Handler that is called when the apply button is pressed. */
  onApply?: (keys: string[]) => void
  clearButtonText?: string
  onClear?: () => void
}

export const CheckboxList = <T extends SelectItem>({
  overlayState,
  ...props
}: CheckboxListProps<T>): JSX.Element => {
  const ref = useRef<HTMLUListElement>(null)
  const state = useListState(props)
  const { gridProps } = useGridList(props, state, ref)

  const onApply = () => {
    props.onApply?.(
      Array.from(state.selectionManager.selectedKeys).map(key => key.toString())
    )
    overlayState.close()
  }

  const onClear = () => {
    props.onClear?.()
    state.selectionManager.clearSelection()
  }

  const shouldFilterItem = (item: Node<object>): boolean => {
    return Boolean(
      props.filterValue &&
        item.textValue &&
        !normalizeValue(item.textValue).includes(
          normalizeValue(props.filterValue)
        )
    )
  }
  const renderItems = () => {
    const items = [...state.collection].filter(item => !shouldFilterItem(item))

    if (items.length === 0) {
      return <div className={styles.notFound}>No matches found</div>
    }

    return items.map(item => {
      const commonProps = { item, state }
      if (item.type === 'section') {
        return <ListSection key={item.key} section={item} {...commonProps} />
      }
      return <ListItem key={item.key} {...commonProps} />
    })
  }

  return (
    <MultiselectStateContext.Provider value={state}>
      <ul className={styles.ul} {...gridProps} ref={ref}>
        {renderItems()}
      </ul>
      <div className={styles.footer}>
        {props.footer}
        {props.applyButtonText && (
          <Button onPress={onApply}>{props.applyButtonText}</Button>
        )}
        {props.clearButtonText && (
          <Button onPress={onClear} intent='secondary' appearance='outline'>
            {props.clearButtonText}
          </Button>
        )}
      </div>
    </MultiselectStateContext.Provider>
  )
}
