import { Position } from 'reactflow'

import {
  ContentId,
  ContentType,
  StartFields,
} from '../../../../domain/models/content-fields'
import { FALLBACK_FLOW } from '../../../constants'
import { useBotConfig } from '../../../custom-hooks/use-bot-config'
import { createFollowUpId, getTargetHandleId } from '../../edges/edge-utils'
import { NodeHandleType } from '../constants'
import { HandleType, TriangleHandle } from './handle'

interface HandlesProps {
  contentId: ContentId
  hasFollowUp?: boolean
  isInvalid?: boolean
}

export const Handles = (props: HandlesProps): JSX.Element => {
  const { isBotActionConnectable } = useBotConfig()
  const isConnectableWithBotAction =
    isBotActionConnectable || props.contentId.type === ContentType.HANDOFF

  return (
    <>
      <TriangleHandle
        handleType={HandleType.Target}
        type={NodeHandleType.Target}
        position={Position.Left}
        id={getTargetHandleId(props.contentId)}
        isConnectableWithBotAction={isConnectableWithBotAction}
      />
      {props.hasFollowUp && (
        <TriangleHandle
          handleType={HandleType.Followup}
          type={NodeHandleType.Source}
          position={Position.Right}
          id={createFollowUpId(props.contentId.id)}
          isConnectableWithBotAction={isConnectableWithBotAction}
          isInvalid={props.isInvalid}
        />
      )}
    </>
  )
}

export const InputHandles = (props: HandlesProps): JSX.Element => {
  return (
    <TriangleHandle
      isInvalid={props.isInvalid}
      handleType={HandleType.Input}
      type={NodeHandleType.Source}
      position={Position.Right}
      id={createFollowUpId(props.contentId.id)}
    />
  )
}

interface StartNodeHandleProps {
  hasPreviousFlows: boolean
  currentFlowId: string
  data: StartFields
  isInvalid?: boolean
}

export const StartNodeHandle = ({
  hasPreviousFlows,
  currentFlowId,
  data,
  isInvalid,
}: StartNodeHandleProps) => {
  const { isBotActionConnectable } = useBotConfig()
  const isConnectableWithBotAction = isBotActionConnectable

  return (
    <TriangleHandle
      handleType={hasPreviousFlows ? HandleType.StartFlow : HandleType.Input}
      type={NodeHandleType.Source}
      position={Position.Right}
      id={`start|${currentFlowId}_${data.id}`}
      isConnectable={currentFlowId !== FALLBACK_FLOW.id}
      isConnectableWithBotAction={isConnectableWithBotAction}
      isInvalid={isInvalid}
    />
  )
}
