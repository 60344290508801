import styled, { css } from 'styled-components'

import { HelperText } from '../base-styles'
import { COLORS } from '../colors'
import { textBody, textBodySmall, textSmall } from '../typography'
import { Size } from '../variants'

export const inputTextArea = css`
  width: 100%;
  padding: 0px;
  background: transparent;
  color: ${COLORS.N900};
  border: none;
  outline: none;

  ::placeholder {
    color: ${COLORS.N500};
  }
`

export const InputContainer = styled.div<{
  $width?: string
  $isReadOnly?: boolean
  $size: Size
}>`
  display: flex;
  align-items: center;
  background: ${props => (props.$isReadOnly ? COLORS.N50 : COLORS.WHITE)};
  border: 1px solid ${COLORS.N400};
  box-sizing: border-box;
  gap: 6px;
  width: ${props => props.$width || '100%'};

  ${props => props.$size === Size.LARGE && sizeLarge};
  ${props => props.$size === Size.MEDIUM && sizeMedium};
  ${props => props.$size === Size.SMALL && sizeSmall};
  ${props => props.$size === Size.TINY && sizeTiny};

  input {
    ${inputTextArea};
  }

  textarea {
    ${inputTextArea};
    min-height: 21px;
    max-height: 325px;
    resize: none;
    margin: 0px -15px;
    padding: 0px 15px;
  }

  svg {
    color: ${COLORS.N500};
  }
`

export const InputFooter = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 2px;
  color: ${COLORS.N500};

  > div:last-child {
    display: flex;
    gap: 4px;
    margin-left: auto;
  }
`

export const StyledHtInput = styled.div<{ $hasError?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  ${HelperText} {
    color: ${props => props.$hasError && COLORS.R600};
  }

  ${InputContainer} {
    border-color: ${props => props.$hasError && COLORS.R600};
  }
`

export const InputIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const EmojiPickerContainer = styled.div`
  em-emoji-picker {
    width: 100%;
    height: 300px;
    margin-top: 4px;
    border: 1px solid ${COLORS.N100};
    --font-family: 'Inter';
    --border-radius: 8px;
    --color-border: ${COLORS.N100};
    --font-size: 14px;
    --rgb-accent: 110, 73, 255;
    --shadow: 5px 5px 15px -8px rgba(0, 0, 0, 0.4);
  }
`

const sizeLarge = css`
  padding: 11px 24px;
  border-radius: 8px;
  min-height: 48px;

  input,
  textarea {
    ${textBody};
  }
`

const sizeMedium = css`
  padding: 11px 16px;
  border-radius: 8px;
  min-height: 45px;

  input,
  textarea {
    ${textBodySmall};
  }
`

const sizeSmall = css`
  padding: 7px 12px;
  border-radius: 8px;
  min-height: 37px;

  input,
  textarea {
    ${textBodySmall};
  }
`

const sizeTiny = css`
  padding: 3px 8px;
  border-radius: 4px;
  min-height: 26px;

  input,
  textarea {
    ${textSmall};
  }
`
