export enum CountryCode {
  AG = 'AG',
  AR = 'AR',
  AT = 'AT',
  BB = 'BB',
  BG = 'BG',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BZ = 'BZ',
  CA = 'CA',
  CH = 'CH',
  CL = 'CL',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  GE = 'GE',
  GF = 'GF',
  GR = 'GR',
  GT = 'GT',
  GY = 'GY',
  HN = 'HN',
  HR = 'HR',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IN = 'IN',
  IS = 'IS',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  KR = 'KR',
  LK = 'LK',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MT = 'MT',
  MX = 'MX',
  MY = 'MY',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  PA = 'PA',
  PE = 'PE',
  PL = 'PL',
  PT = 'PT',
  PY = 'PY',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  SA = 'SA',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  SR = 'SR',
  SV = 'SV',
  TH = 'TH',
  TR = 'TR',
  TT = 'TT',
  UA = 'UA',
  US = 'US',
  UY = 'UY',
  VE = 'VE',
  VN = 'VN',
  ZA = 'ZA',
}
