import { Edge } from 'reactflow'

import { MAX_SECTION_TITLE_LENGTH } from '../../../../UI/constants'
import { IdMapping } from '../../../../UI/types'
import { HtWhatsappButtonListSection } from '../../cms/hubtype/whatsapp'
import { LocaleCode } from '../../locales/code'
import { LocaleManager } from '../../utils/locale-manager'
import { TextLocale } from '../common'
import { TopContentFieldsBase } from '../content-bases'
import { ContentType, SubContentType } from '../content-types'
import { WhatsappButtonListFields } from './whatsapp-button-list-fields'
import { WhatsappButtonListRowFields } from './whatsapp-button-list-row-fields'

export class WhatsappButtonListSectionFields extends TopContentFieldsBase {
  constructor(
    public title = '',
    public rows: WhatsappButtonListRowFields[] = [],
    public titleLocales: TextLocale[] = []
  ) {
    super()
  }

  contentType(): SubContentType {
    return ContentType.SECTION
  }

  static fromHubtypeCMS(
    section: HtWhatsappButtonListSection,
    locale: LocaleCode
  ): WhatsappButtonListSectionFields {
    const newWhatsappWhatsappButtonListSectionFields =
      new WhatsappButtonListSectionFields()
    newWhatsappWhatsappButtonListSectionFields.id = section.id
    newWhatsappWhatsappButtonListSectionFields.titleLocales = section.title
    newWhatsappWhatsappButtonListSectionFields.rows = section.rows.map(row =>
      WhatsappButtonListRowFields.fromHubtypeCMS(row, locale)
    )
    newWhatsappWhatsappButtonListSectionFields.setValuesByLocale(locale)
    return newWhatsappWhatsappButtonListSectionFields
  }

  toHubtypeCMS(locale: LocaleCode): HtWhatsappButtonListSection {
    this.setContentByLocale(locale)
    return {
      id: this.id,
      title: this.titleLocales,
      rows: this.rows.map(row => row.toHubtypeCMS(locale)),
    }
  }

  setValuesByLocale(locale: string): void {
    this.title = LocaleManager.getTextByLocale(this.titleLocales, locale)
    this.rows.forEach(row => row.setValuesByLocale(locale))
  }

  setContentByLocale(locale: LocaleCode): void {
    this.titleLocales = LocaleManager.setTextByLocale(
      this.titleLocales,
      this.title,
      locale
    )
    this.rows.forEach(row => row.setContentByLocale(locale))
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.titleLocales, newLocale, copyFrom)
    this.rows.forEach(row => row.addLocale(newLocale, copyFrom))
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.titleLocales, localesToRemove)
    this.rows.forEach(row => row.removeLocales(localesToRemove))
  }

  getErrors(parent: WhatsappButtonListFields): string[] {
    const requiredFields = []
    if (!this.title) requiredFields.push('Section title')
    if (this.rows.length === 0) requiredFields.push('Row')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const tooLongFields = []
    if (this.title.length > MAX_SECTION_TITLE_LENGTH) {
      tooLongFields.push('Section title')
    }
    const fieldToLongErrors = this.getTooLongFieldsErrors(tooLongFields)
    const rowErrors = this.rows.flatMap(row => row.getErrors(parent.edges))
    return [...fieldErrors, ...fieldToLongErrors, ...rowErrors]
  }

  getLocalesWithErrorsInSection(
    locales: LocaleCode[],
    numberOfSections: number
  ): string[] {
    const titleErrors =
      numberOfSections > 1
        ? LocaleManager.getLocalesWithEmptyText(this.titleLocales, locales)
        : []
    const rowErrors = this.rows.flatMap(row =>
      row.getLocalesWithErrors(locales)
    )
    this.errors.localesWithErrors = [...titleErrors, ...rowErrors]
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.title], value)) {
      return true
    }
    const includesString = this.rows.map(r => r.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): (Edge | undefined)[] {
    return this.rows.map(r => r.getConnections(edges))
  }

  static getCopy(
    content: WhatsappButtonListSectionFields
  ): WhatsappButtonListSectionFields {
    const newWhatsappButtonListSection = new WhatsappButtonListSectionFields()
    TopContentFieldsBase.copyContent(content, newWhatsappButtonListSection)
    newWhatsappButtonListSection.rows = content.rows.map(row =>
      WhatsappButtonListRowFields.getCopy(row)
    )
    return newWhatsappButtonListSection
  }

  getIdMappingForOldFields(
    oldSection: WhatsappButtonListSectionFields
  ): IdMapping {
    return this.rows.reduce((idMapping: IdMapping, row, i) => {
      idMapping[oldSection.rows[i].id] = row.id
      return idMapping
    }, {})
  }
}
