import { cva } from 'class-variance-authority'

import Icon from '../icon/icon'
import styles from './banner.module.css'

const bannerClasses = cva(styles.base, {
  variants: {
    intent: {
      info: styles.info,
      error: styles.error,
      warning: styles.warning,
      neutral: styles.neutral,
    },
    hasTitle: {
      true: styles.withTitle,
      false: styles.withoutTitle,
    },
    isEmptyState: {
      true: [styles.emptyState, styles.neutral],
    },
  },
})

export interface BannerProps {
  /** The title of the banner. */
  title?: string
  /** The description of the banner. */
  description: string
  /** The intent of the banner. */
  intent?: 'info' | 'error' | 'warning' | 'neutral'
  /** Whether to hide the icon. */
  isIconHidden?: boolean
  /** Whether the banner is an empty state. */
  isEmptyState?: boolean
  /** The children of the banner. */
  children?: React.ReactNode
}

/** A banner displays an important, succinct message, and provides actions for users to address (or dismiss the banner). It requires a user action to be dismissed. We are using banners for different purposes: New features, new pages, changes in our views, etc. */
export function Banner({
  title,
  description,
  intent = 'info',
  isEmptyState,
  isIconHidden,
  children,
}: BannerProps) {
  return (
    <div
      className={bannerClasses({
        intent,
        isEmptyState,
        hasTitle: !!title,
      })}
      role='banner'
    >
      <div className={styles.content}>
        {(title || !isIconHidden) && (
          <div className={styles.title}>
            {!isIconHidden && (
              <Icon className={styles.bannerIcon} icon='circle-info' />
            )}
            {title && <span>{title}</span>}
          </div>
        )}
        <p className={styles.description}>{description}</p>
      </div>
      {children && <div className={styles.actions}>{children}</div>}
    </div>
  )
}

export default Banner
