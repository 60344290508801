import { Icon, IconButton } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { AssetFields } from '../../../../domain/models/content-fields'
import { ARIA_LABEL } from '../../../roles'
import { TextSmallBold } from '../../base'
import { StyledHtInput } from '../../base/ht-input/ht-input-styles'
import { InputAction } from '../constants'
import { ImageContainer, ImageLabel, ImageText } from '../editor-styles'
import { EditorProps } from '../node-editor-panel'

interface ImageFieldProps extends EditorProps {
  changeProps?: { elementIndex: number }
  field: InputAction
  image?: string
  uploadFile(file: File): Promise<AssetFields | null>
}

export const ContentImageField = memo((props: ImageFieldProps) => {
  return (
    <StyledHtInput>
      <TextSmallBold>Image</TextSmallBold>
      <ImageContainer $isReadOnly={props.isReadOnly}>
        {props.image ? (
          <>
            <img src={props.image} />
            {!props.isReadOnly && <RemoveImageButton {...props} />}
          </>
        ) : (
          <ImageText>Image</ImageText>
        )}
        {!props.isReadOnly && (
          <ImageLabel>
            <Icon icon='arrow-up-from-bracket' />
            <ImageInput {...props} />
          </ImageLabel>
        )}
      </ImageContainer>
    </StyledHtInput>
  )
})

export const RemoveImageButton = memo((props: ImageFieldProps) => {
  const onClick = () => {
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: undefined,
    })
  }
  return <IconButton icon='trash-can' size='small' onPress={onClick} />
})

export const ImageInput = memo((props: ImageFieldProps) => {
  const uploadImage = async (file: File) => {
    const newAsset = await props.uploadFile(file)
    if (!newAsset) return
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: newAsset,
    })
  }

  return (
    <input
      aria-label={ARIA_LABEL.IMAGE_INPUT}
      accept='image/jpeg, image/png'
      style={{ display: 'none' }}
      type='file'
      onChange={({ target }) => {
        if (target.files && target.files[0]) {
          uploadImage(target.files[0])
        }
      }}
    />
  )
})
