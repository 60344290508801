import { useEffect, useRef, useState } from 'react'
import { useOverlayTrigger } from 'react-aria'
import { useOverlayTriggerState } from 'react-stately'

import Field from '../../field/field'
import { Popover } from '../../popover/popover'
import { DropdownButton } from './dropdown-button/dropdown-button'
import { SelectDropdownProps } from './select-dropdown'

/**
 * This component handles the case when a Select has no items.
 * React Aria's useSelect hook doesn't support opening selects with empty collections,
 * so we use useOverlayTrigger instead as a workaround.
 * @see https://github.com/adobe/react-spectrum/issues/4589
 */
export const EmptySelect = <T extends object>(
  props: SelectDropdownProps<T>
): JSX.Element => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const state = useOverlayTriggerState(props)
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'menu' },
    state,
    triggerRef
  )

  const [buttonWidth, setButtonWidth] = useState<string>()
  useEffect(() => {
    if (triggerRef.current) {
      setButtonWidth(triggerRef.current.offsetWidth + 'px')
    }
  }, [triggerRef])

  return (
    <Field {...props}>
      <DropdownButton
        {...triggerProps}
        buttonRef={triggerRef}
        aria-label={props.label}
        isOpen={state.isOpen}
        isEmpty={!props.preserveButtonLabel}
        isReadOnly={props.isReadOnly}
        isInvalid={props.isInvalid}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        {...props.buttonProps}
      >
        {props.placeholder || props.label}
      </DropdownButton>
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={triggerRef}
          width={buttonWidth}
          offset={4}
          {...props.popoverProps}
        >
          <div {...overlayProps}>{props.header}</div>
        </Popover>
      )}
    </Field>
  )
}
