import { cva, cx } from 'class-variance-authority'
import { Connection, Handle, HandleProps } from 'reactflow'

import { ContentType } from '../../../../domain/models/content-fields'
import ConnectionHandleIcon from '../../../assets/connection-handle.svg'
import { useFeedbackMessage } from '../../../custom-hooks/use-feedback-message'
import styles from './handle.module.css'

const handleClasses = cva(styles.base, {
  variants: {
    type: {
      button: styles.buttonHandle,
      condition: styles.defaultHandle,
      element: styles.defaultHandle,
      fallback1: styles.fallback1Handle,
      followup: styles.followupHandle,
      input: styles.inputHandle,
      section: styles.sectionHandle,
      smallCondition: styles.smallConditionHandle,
      startFlow: styles.startFlow,
      target: styles.targetHandle,
    },
    isHidden: {
      true: styles.hidden,
    },
    isInvalid: {
      true: styles.invalid,
    },
  },
})

export enum HandleType {
  Button = 'button',
  Condition = 'condition',
  Element = 'element',
  Fallback1 = 'fallback1',
  Followup = 'followup',
  Input = 'input',
  Section = 'section',
  SmallCondition = 'smallCondition',
  StartFlow = 'startFlow',
  Target = 'target',
}

interface TriangleHandleProps extends HandleProps {
  handleType: HandleType
  isHidden?: boolean
  isInvalid?: boolean
  isConnectableWithBotAction?: boolean
}

export const TriangleHandle = ({
  handleType,
  isHidden,
  isInvalid,
  isConnectableWithBotAction = true,
  ...props
}: TriangleHandleProps): JSX.Element => {
  const { reportInform } = useFeedbackMessage()

  const isValidConnection = (connection: Connection): boolean => {
    if (
      connection.targetHandle?.includes(ContentType.BOT_ACTION) &&
      !isConnectableWithBotAction
    ) {
      reportInform('This content can not be connected to a bot action')
      return false
    }
    return true
  }

  return (
    <>
      {isInvalid && (
        <div
          className={cx(
            styles.warningCircle,
            handleClasses({ type: handleType })
          )}
        />
      )}
      <Handle
        {...props}
        className={cx(
          styles.handle,
          handleClasses({ type: handleType, isHidden, isInvalid })
        )}
        isValidConnection={isValidConnection}
      >
        <ConnectionHandleIcon />
      </Handle>
    </>
  )
}
