import { Edge } from 'reactflow'

import { NonMessageContents } from '../../../UI/types'
import {
  HtButton,
  HtPayloadLocale,
  HtTextLocale,
  HtUrlLocale,
} from '../cms/hubtype/common'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import {
  ContentType,
  PayloadFields,
  SubContentType,
  TopContentFieldsBase,
  UrlFields,
} from '.'
import { PayloadLocale, TextLocale, UrlLocale } from './common'

export class ButtonFields extends TopContentFieldsBase {
  public url?: UrlFields
  public payload?: PayloadFields
  constructor(
    public text = '',
    public textLocales: TextLocale[] = [],
    public urlLocales: UrlLocale[] = [],
    public payloadLocales: PayloadLocale[] = []
  ) {
    super()
  }

  contentType(): SubContentType {
    return ContentType.BUTTON
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.text = LocaleManager.getTextByLocale(this.textLocales, locale)
    this.url = LocaleManager.getNonMessageContentByLocale(
      this.urlLocales,
      locale,
      nonMessageContents.urls
    )
    this.payloadLocales = this.payloadLocales.reduce(
      (acc: PayloadLocale[], payloadLocale) => {
        if (
          nonMessageContents.payloads.some(
            field => field.id === payloadLocale?.id
          )
        ) {
          acc.push(payloadLocale)
        }
        return acc
      },
      []
    )
    this.payload = LocaleManager.getNonMessageContentByLocale(
      this.payloadLocales,
      locale,
      nonMessageContents.payloads
    )
  }

  setContentByLocale(locale: LocaleCode): void {
    this.textLocales = LocaleManager.setTextByLocale(
      this.textLocales,
      this.text,
      locale
    )

    const newUrlLocales = this.urlLocales.filter(
      urlLocale => urlLocale.locale !== locale
    )
    if (this.url) {
      newUrlLocales.push({ id: this.url.id, locale })
    }
    this.urlLocales = newUrlLocales

    // TODO: remove payload from button
    const newPayloadLocales = this.payloadLocales.filter(
      payloadLocale => payloadLocale.locale !== locale
    )
    if (this.payload) {
      newPayloadLocales.push({
        id: this.payload.id,
        locale,
      })
    }
    this.payloadLocales = newPayloadLocales
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.textLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.urlLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.payloadLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.textLocales, localesToRemove)
    LocaleManager.removeLocales(this.urlLocales, localesToRemove)
    LocaleManager.removeLocales(this.payloadLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    button: HtButton,
    locale: string,
    nonMessageContents: NonMessageContents
  ): ButtonFields {
    const newButton = new ButtonFields()
    newButton.id = button.id
    newButton.textLocales = button.text.map(
      textLocale => textLocale as TextLocale
    )
    newButton.urlLocales = button.url.map(urlLocale => urlLocale as UrlLocale)
    newButton.payloadLocales = button.payload.map(
      payloadLocale => payloadLocale as PayloadLocale
    )
    newButton.setValuesByLocale(locale, nonMessageContents)
    return newButton
  }

  toHubtypeCMS(locale: LocaleCode): HtButton {
    this.setContentByLocale(locale)
    const button: HtButton = {
      id: this.id,
      text: this.textLocales as HtTextLocale[],
      url: this.urlLocales as HtUrlLocale[],
      payload: this.payloadLocales as HtPayloadLocale[],
      hidden: [],
    }
    return button
  }

  getErrors(edges: Edge[]): string[] {
    const requiredFields = []
    if (!this.text) requiredFields.push('Button text')
    if (
      !this.url &&
      !edges.some(edge => edge.sourceHandle?.includes(this.id))
    ) {
      requiredFields.push('Connection')
    }
    return this.getMissingFieldsErrors(requiredFields)
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    return LocaleManager.getLocalesWithEmptyText(this.textLocales, locales)
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString(
      [this.text, this.url?.name, this.payload?.name],
      value
    )
  }

  getConnections(edges: Edge[]): Edge | undefined {
    return edges.find(e => e.sourceHandle === this.id)
  }

  static getCopy(content: ButtonFields): ButtonFields {
    const newButton = new ButtonFields()
    TopContentFieldsBase.copyContent(content, newButton)
    if (content.url) {
      newButton.url = UrlFields.getCopy(content.url)
    }
    if (content.payload) {
      newButton.payload = PayloadFields.getCopy(content.payload)
    }
    return newButton
  }

  hasUrl(urlId: string, currentLocale: LocaleCode): boolean {
    return (
      this.url?.id === urlId ||
      this.urlLocales.some(
        url => url.id === urlId && url.locale !== currentLocale
      )
    )
  }
}
