import { Icon, Tooltip } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'

import { FeedbackType } from '../../../../domain/feedback'
import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { FadeInOutContainer, FadeOutContainer, HtSnackbar } from '../../base'
import styles from '../header.module.css'

export const ONLINE_TIMEOUT = 2000
export const OFFLINE_TIMEOUT = 10000

export const ConnectionStatus = (): JSX.Element => {
  const analytics = useAnalytics()
  const { state } = useFlowBuilderSelector(ctx => ctx)

  const [isConnectionInstable, toggleConnectionInstability] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [isOnlineIconVisible, toggleOnlineIconVisibility] = useState(false)
  const offlineTimeoutRef = useRef<any>(null)
  const onlineTimeoutRef = useRef<any>(null)

  useEffect(() => {
    toggleSnackbarVisibility(isConnectionInstable)
  }, [isConnectionInstable])

  const onOnline = () => {
    analytics.trackEvent(TrackEventName.CONNECTION_RECOVERED, {
      recovered_less_10sec: !isConnectionInstable,
    })
    handleOnlineStatusChange()
    toggleConnectionInstability(false)
    if (!isConnectionInstable) return
    toggleOnlineIconVisibility(true)
    onlineTimeoutRef.current = setTimeout(() => {
      toggleOnlineIconVisibility(false)
    }, ONLINE_TIMEOUT)
  }

  const onOffline = () => {
    handleOnlineStatusChange()
    offlineTimeoutRef.current = setTimeout(() => {
      toggleConnectionInstability(true)
    }, OFFLINE_TIMEOUT)
  }

  const handleOnlineStatusChange = () => {
    clearTimeout(offlineTimeoutRef.current)
    clearTimeout(onlineTimeoutRef.current)
  }

  useEffect(() => {
    if (state.isOffline) {
      onOffline()
    } else {
      onOnline()
    }
  }, [state.isOffline])

  return (
    <>
      <Tooltip
        content='Your connection seems to not be working. Saving and publishing are disabled until connection is reestablished.'
        isDisabled={isSnackbarVisible || !isConnectionInstable}
      >
        <div className={styles.connectionStatus}>
          <FadeInOutContainer $isActive={isOnlineIconVisible}>
            <Icon icon='cloud-check' size='large' prefix='far' />
          </FadeInOutContainer>
          <FadeOutContainer $isActive={isConnectionInstable}>
            <Icon icon='cloud-slash' size='large' prefix='far' />
          </FadeOutContainer>
        </div>
      </Tooltip>
      {isConnectionInstable && isSnackbarVisible && (
        <HtSnackbar
          title='Your connection seems to not be working.'
          text='You will be disconnected from Flow Builder in 20 seconds.'
          type={FeedbackType.CONNECTION_ISSUE}
          onClose={() => toggleSnackbarVisibility(false)}
        />
      )}
    </>
  )
}
