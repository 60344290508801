import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { isValidJSON } from '../../../utils/string-utils'
import { HtBotActionNode } from '../cms/hubtype/bot-action'
import { HtContentType } from '../cms/hubtype/node-types'
import { ContentType, TopContentFieldsBase } from '.'
import { MessageContentType } from './content-types'

export class BotActionFields extends TopContentFieldsBase {
  public payloadId?: string

  constructor(public payloadParams = '') {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.BOT_ACTION
  }

  static getDisplayInfo() {
    return { name: 'Bot Action', icon: 'code' }
  }

  getDisplayInfo() {
    return BotActionFields.getDisplayInfo()
  }

  static fromHubtypeCMS(component: HtBotActionNode): BotActionFields {
    const newBotAction = new BotActionFields()
    this.setHubtypeCmsCommonData(newBotAction, component)
    newBotAction.payloadId = component.content.payload_id
    newBotAction.payloadParams = component.content.payload_params || ''
    return newBotAction
  }

  toHubtypeCMS(position: XYPosition): HtBotActionNode {
    const node: HtBotActionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.BOT_ACTION,
      content: {
        payload_id: this.payloadId,
        payload_params: this.payloadParams,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.payloadId) requiredFields.push('Payload')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    if (this.payloadParams && !isValidJSON(this.payloadParams)) {
      fieldErrors.push(`Payload params is not a valid JSON.`)
    }
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code, this.payloadParams], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: BotActionFields): BotActionFields {
    const newBotAction = new BotActionFields()
    TopContentFieldsBase.copyContent(content, newBotAction)
    return newBotAction
  }
}
