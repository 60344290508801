import { useEffect, useRef, useState } from 'react'
import { AriaMenuTriggerProps, useMenuTrigger } from 'react-aria'
import { ListProps, useMenuTriggerState } from 'react-stately'

import Field from '../../field/field'
import { Popover } from '../../popover/popover'
import { SelectItem, SelectProps } from '../../select/select'
import { DropdownButton } from '../../select/widgets/dropdown-button/dropdown-button'
import { CheckboxList } from './list'

export interface MultiselectMenuProps<T extends SelectItem>
  extends Omit<ListProps<T>, 'children' | 'disabledKeys' | 'items'>,
    AriaMenuTriggerProps,
    Omit<
      SelectProps<T>,
      'renderButtonAsItem' | 'isClearable' | 'onChange' | 'preserveButtonLabel'
    > {
  applyButtonText?: string
  onApply?: (keys: string[]) => void
  clearButtonText?: string
  onClear?: () => void
}

export const MultiselectMenu = <T extends SelectItem>(
  props: MultiselectMenuProps<T>
): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null)
  const state = useMenuTriggerState(props)
  const { menuTriggerProps } = useMenuTrigger(props, state, ref)

  const [buttonWidth, setButtonWidth] = useState<string>()
  useEffect(() => {
    if (ref.current) {
      setButtonWidth(ref.current.offsetWidth + 'px')
    }
  }, [ref])

  return (
    <Field {...props}>
      <DropdownButton
        {...props.buttonProps}
        {...menuTriggerProps}
        buttonRef={ref}
        isOpen={state.isOpen}
        isLoading={props.isLoading}
        isDisabled={props.isDisabled}
        isInvalid={props.isInvalid}
        isReadOnly={props.isReadOnly}
      >
        {props.placeholder || props.label}
      </DropdownButton>
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={ref}
          width={buttonWidth}
          offset={4}
          {...props.popoverProps}
        >
          {props.header}
          <CheckboxList {...props} overlayState={state} />
        </Popover>
      )}
    </Field>
  )
}
