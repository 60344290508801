import { Badge, Icon, IconName } from '@hubtype/ui-react-web'
import { CSSProperties, memo } from 'react'
import { Position } from 'reactflow'

import {
  ButtonFields,
  ElementFields,
  TopContentFields,
} from '../../../domain/models/content-fields'
import { useBotConfig } from '../../custom-hooks/use-bot-config'
import {
  COLORS,
  TextBodySmall,
  TextBodySmallBold,
  TextSmall,
  TextSmallBold,
  TextSmallExtra,
} from '../base'
import { NodeHandleType } from './constants'
import { HandleType, TriangleHandle } from './handles/handle'
import { Markdown, MarkdownText } from './markdown/markdown'
import {
  Border,
  ButtonContainer,
  ClosedElement,
  Code,
  ConditionContainer,
  Header,
  OpenedElement,
  StyledInputList,
  Title,
} from './node-styles'

interface NodeHeaderProps {
  data: TopContentFields
  iconComponent?: React.ReactNode
  hideCode?: boolean
  isWhatsapp?: boolean
}

export const NodeHeader = memo((props: NodeHeaderProps): JSX.Element => {
  const displayInfo = props.data.getDisplayInfo()
  return (
    <>
      <NodeHeaderCode {...props} />
      <Header>
        <Title>{displayInfo.name}</Title>
        <div>
          {props.isWhatsapp && (
            <Icon icon='whatsapp' size='large' prefix='fab' />
          )}
          {!props.iconComponent && (
            <Icon icon={displayInfo.icon} size='large' />
          )}
          {props.iconComponent && props.iconComponent}
        </div>
      </Header>
    </>
  )
})

export const NodeHeaderCode = memo((props: NodeHeaderProps): JSX.Element => {
  const hasErrors =
    props.data.errors.showErrors &&
    (props.data.errors.fieldErrors.length > 0 ||
      props.data.errors.hasDuplicatedCode)
  return (
    <Code>
      {!props.hideCode && (
        <TextSmallExtra $withEllipsis>{props.data.code}</TextSmallExtra>
      )}
      {hasErrors && (
        <Icon icon='exclamation-triangle' size='medium' prefix='fad' />
      )}
    </Code>
  )
})

interface CarouselElementProps {
  element: ElementFields
  isCarouselSelected: boolean
  isElementSelected: boolean
  index: number
  isInvalid?: boolean
  selectElement: () => void
}
export const CarouselElement = memo(
  ({
    element,
    isElementSelected,
    isCarouselSelected,
    index,
    isInvalid,
    selectElement,
  }: CarouselElementProps): JSX.Element => {
    const getEmptyStyle = (value?: string): CSSProperties => {
      if (value || isCarouselSelected) return {}
      return { color: COLORS.N500 }
    }
    if (isElementSelected) {
      return (
        <OpenedElement $hasBorder={index === 0} onClick={() => selectElement()}>
          <TextBodySmallBold style={getEmptyStyle(element.title)}>
            {element.title || 'Element title'}
          </TextBodySmallBold>
          {element.subtitle && (
            <MarkdownText isSelected={isElementSelected}>
              {element.subtitle}
            </MarkdownText>
          )}
          {element.image?.assetUrl && <img src={element.image.assetUrl} />}
          <Button button={element.buttons[0]} />
        </OpenedElement>
      )
    } else {
      return (
        <ClosedElement $hasBorder={index !== 0} onClick={() => selectElement()}>
          <TextBodySmall style={getEmptyStyle(element.title)}>
            {element.title || 'Element title'}
          </TextBodySmall>
          {element.image?.assetUrl && <img src={element.image.assetUrl} />}
          <TriangleHandle
            handleType={HandleType.Element}
            type={NodeHandleType.Source}
            position={Position.Right}
            id={element.buttons[0].id}
            isHidden={!hasButtonHandle(element.buttons[0])}
            isInvalid={isInvalid}
          />
        </ClosedElement>
      )
    }
  }
)

interface ButtonProps {
  button: ButtonFields
  hasHandler?: boolean
  isReply?: boolean
  isInvalid?: boolean
  onClick?(): void
}
export const Button = memo(
  ({
    button,
    isReply,
    hasHandler = true,
    isInvalid,
    onClick,
  }: ButtonProps): JSX.Element => {
    const buttonUrl = button.url?.name
    const buttonPayload = button.payload?.name
    return (
      <ButtonContainer
        onClick={onClick}
        $isReply={isReply}
        $isEmpty={!button.text}
      >
        <Markdown>{button.text || 'Button'}</Markdown>
        {!isReply && buttonUrl && (
          <a href={buttonUrl} target='_blank' rel='noopener noreferrer'>
            <Icon icon='link' size='medium' />
          </a>
        )}
        {!buttonUrl && buttonPayload && <Icon icon='code' size='medium' />}
        {hasHandler && (
          <TriangleHandle
            handleType={HandleType.Button}
            type={NodeHandleType.Source}
            position={Position.Right}
            id={button.id}
            isHidden={!hasButtonHandle(button, isReply)}
            isInvalid={isInvalid}
          />
        )}
      </ButtonContainer>
    )
  }
)

interface ConditionProps {
  id: string
  children: React.ReactNode
  handleType?: HandleType
  icon?: IconName
  border: Border | null
  isConnectableWithBotAction?: boolean
  isInvalid?: boolean
  onClick?(): void
}
export const Condition = memo(
  ({
    id,
    children,
    icon,
    handleType,
    border,
    isConnectableWithBotAction = false,
    isInvalid,
    onClick,
  }: ConditionProps): JSX.Element => {
    const { isBotActionConnectable } = useBotConfig()
    isConnectableWithBotAction = isBotActionConnectable

    return (
      <ConditionContainer
        $border={border}
        $isSmallCondition={handleType === HandleType.SmallCondition}
        onClick={onClick}
      >
        {children}
        {icon && (
          <Icon
            icon={icon}
            size='large'
            prefix={icon === 'messages' ? 'fas' : 'fab'}
          />
        )}
        <TriangleHandle
          handleType={handleType || HandleType.Condition}
          type={NodeHandleType.Source}
          position={Position.Right}
          id={id}
          isConnectableWithBotAction={isConnectableWithBotAction}
          isInvalid={isInvalid}
        />
      </ConditionContainer>
    )
  }
)

interface NodeSectionProps {
  title: string
  name?: string
  disableDivision?: boolean
}
export const NodeSection = memo(
  ({ name, title, disableDivision }: NodeSectionProps): JSX.Element => {
    return (
      <>
        {!disableDivision && <hr />}
        <div>
          <TextSmallBold>{title}</TextSmallBold>
          {name && (
            <TextBodySmall $wordBreak='break-word'>{name}</TextBodySmall>
          )}
        </div>
      </>
    )
  }
)

interface ChipListProps {
  items: string[]
  selected: boolean
}

export const ChipList = ({ items, selected }: ChipListProps) => {
  const remainingItems = items.length - 3
  return items.length > 0 ? (
    <StyledInputList>
      {items.slice(0, 3).map((item, index) => (
        <Badge key={index} intent={selected ? 'primary' : 'neutral'}>
          {item}
        </Badge>
      ))}
      {remainingItems > 0 && <TextSmall>+{remainingItems} more</TextSmall>}
    </StyledInputList>
  ) : (
    <></>
  )
}

const hasButtonHandle = (button: ButtonFields, isReply?: boolean) => {
  return (!button.payload && !button.url) || (isReply && !button.payload)
}
