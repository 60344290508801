/* eslint-disable @typescript-eslint/no-empty-function */
import { Edge, XYPosition } from 'reactflow'

import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  ButtonFields,
  ContentType,
  MessageContentType,
  TextLocale,
  TopContentFieldsBase,
} from '../../domain/models/content-fields'
import { LocaleCode } from '../../domain/models/locales/code'
import { LocaleManager } from '../../domain/models/utils/locale-manager'
import { NodeEdges } from '../../UI/components/edges/edge-utils'
import {
  MAX_WHATSAPP_BUTTON_TEXT_LENGTH,
  MAX_WHATSAPP_FOOTER_TEXT_LENGTH,
  MAX_WHATSAPP_HEADER_TEXT_LENGTH,
  MAX_WHATSAPP_MAIN_TEXT_LENGTH,
} from '../../UI/constants'
import { IdMapping, NonMessageContents } from '../../UI/types'
import { HtWhatsappCTAUrlButtonNode } from './ht-model'

export class WhatsappCTAUrlButtonFields extends TopContentFieldsBase {
  public textLocales: TextLocale[] = []
  public headerLocales: TextLocale[] = []
  public footerLocales: TextLocale[] = []
  constructor(
    public text = '',
    public button = new ButtonFields(),
    public header = '',
    public footer = ''
  ) {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.WHATSAPP_CTA_URL_BUTTON
  }

  static getDisplayInfo() {
    return { name: 'URL Button', icon: 'link' }
  }

  getDisplayInfo() {
    return WhatsappCTAUrlButtonFields.getDisplayInfo()
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.text = LocaleManager.getTextByLocale(this.textLocales, locale)
    this.header = LocaleManager.getTextByLocale(this.headerLocales, locale)
    this.footer = LocaleManager.getTextByLocale(this.footerLocales, locale)
    this.button.setValuesByLocale(locale, nonMessageContents)
  }

  setContentByLocale(locale: LocaleCode): void {
    this.textLocales = LocaleManager.setTextByLocale(
      this.textLocales,
      this.text,
      locale
    )
    this.headerLocales = LocaleManager.setTextByLocale(
      this.headerLocales,
      this.header,
      locale
    )
    this.footerLocales = LocaleManager.setTextByLocale(
      this.footerLocales,
      this.footer,
      locale
    )
    this.button.setContentByLocale(locale)
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.textLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.headerLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.footerLocales, newLocale, copyFrom)
    this.button.addLocale(newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.textLocales, localesToRemove)
    LocaleManager.removeLocales(this.headerLocales, localesToRemove)
    LocaleManager.removeLocales(this.footerLocales, localesToRemove)
    this.button.removeLocales(localesToRemove)
  }

  static fromHubtypeCMS(
    component: HtWhatsappCTAUrlButtonNode,
    locale: LocaleCode,
    nonMessageContents: NonMessageContents
  ): WhatsappCTAUrlButtonFields {
    const newWhatsappCTAUrlButton = new WhatsappCTAUrlButtonFields()
    this.setHubtypeCmsCommonData(newWhatsappCTAUrlButton, component)
    newWhatsappCTAUrlButton.textLocales = component.content.text
    newWhatsappCTAUrlButton.headerLocales = component.content.header
    newWhatsappCTAUrlButton.footerLocales = component.content.footer
    newWhatsappCTAUrlButton.setValuesByLocale(locale, nonMessageContents)
    return newWhatsappCTAUrlButton
  }

  toHubtypeCMS(
    position: XYPosition,
    locale: LocaleCode
  ): HtWhatsappCTAUrlButtonNode {
    this.setContentByLocale(locale)
    const node: HtWhatsappCTAUrlButtonNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.WHATSAPP_CTA_URL_BUTTON,
      content: {
        text: this.textLocales,
        header: this.headerLocales,
        footer: this.footerLocales,
        button: this.button.toHubtypeCMS(locale),
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    const tooLongTextErrors = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.text) requiredFields.push('Main text')
    if (!this.button.text) requiredFields.push('Button text')
    if (!this.button.url) requiredFields.push('Button URL')
    if (this.text.length > MAX_WHATSAPP_MAIN_TEXT_LENGTH) {
      tooLongTextErrors.push('Main text')
    }
    if (this.header.length > MAX_WHATSAPP_HEADER_TEXT_LENGTH) {
      tooLongTextErrors.push('Header')
    }
    if (this.footer.length > MAX_WHATSAPP_FOOTER_TEXT_LENGTH) {
      tooLongTextErrors.push('Footer')
    }
    if (this.button.text.length > MAX_WHATSAPP_BUTTON_TEXT_LENGTH) {
      tooLongTextErrors.push('Button text')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const fieldToLongErrors = this.getTooLongFieldsErrors(tooLongTextErrors)
    this.errors.fieldErrors = [...fieldErrors, ...fieldToLongErrors]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    const textErrors = LocaleManager.getLocalesWithEmptyText(
      this.textLocales,
      locales
    )
    const buttonErrors = this.button.getLocalesWithErrors(locales)
    this.errors.localesWithErrors = [...textErrors, ...buttonErrors]
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return (
      this.fieldsIncludeString(
        [this.code, this.text, this.header, this.footer],
        value
      ) || this.button.hasString(value)
    )
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(
    content: WhatsappCTAUrlButtonFields
  ): WhatsappCTAUrlButtonFields {
    const newWhatsappCTAUrlButton = new WhatsappCTAUrlButtonFields()
    TopContentFieldsBase.copyContent(content, newWhatsappCTAUrlButton)
    newWhatsappCTAUrlButton.button = ButtonFields.getCopy(content.button)
    return newWhatsappCTAUrlButton
  }

  getIdMappingForOldFields(
    oldWhatsappCTAUrlButton: WhatsappCTAUrlButtonFields
  ): IdMapping {
    return { [oldWhatsappCTAUrlButton.button.id]: this.button.id }
  }
}
