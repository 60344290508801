import { flatten } from '@hubtype/util-shared'
import { Item, Section } from 'react-stately'

import { SelectItem, SelectProps } from './select'
import { SelectDropdown } from './widgets/select-dropdown'

export interface SelectSection<T extends SelectItem> {
  title?: string
  items: T[]
}
export interface SelectWithSectionsProps<T extends SelectItem>
  extends Omit<SelectProps<T>, 'items'> {
  sections: SelectSection<T>[]
}

export const SelectWithSections = <T extends SelectItem>({
  sections,
  ...props
}: SelectWithSectionsProps<T>): JSX.Element => {
  const onSelectionChange = (id?: React.Key) => {
    const flattenedData = flatten(sections.map(s => s.items))
    const selectedValue = flattenedData.find(item => item.id === id)
    props.onChange?.(selectedValue)
  }

  return (
    <SelectDropdown
      {...props}
      items={sections}
      onSelectionChange={onSelectionChange}
    >
      {item => (
        <Section key={item.title} items={item.items} title={item.title}>
          {item => (
            <Item textValue={item.name || item.id}>
              {props.children?.(item) || item.name || item.id}
            </Item>
          )}
        </Section>
      )}
    </SelectDropdown>
  )
}
